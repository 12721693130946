<template>
  <div class="page-content">
    <!-- Breadcrumb -->
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
      </ol>
    </nav>
    <!-- Page title -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card dash-card">
          <div class="card-body">
            <h6 class="dash-title mb-2">
              {{ `¡Buenos días ${name}!` }}
            </h6>
            <p class="dash-description mb-0">{{ descripcion }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
       
      </div>
    </div>
  </div>
</template>

<script>
import {capitalize} from '@/utils'
export default {
  name: "Dashboard",
  components: {
    
  },
  data() {
    return {
      breadcrumbA: "Dashboard",
      descripcion:
        "Generá contenido para la aplicación, cargá calorías en las recetas, tags nutricionales y artículos para el blog.",
      // nombreUsuario: userData.leg_nombre[0]+userData.leg_nombre.slice(1).toLowerCase()
      name: "",
      editor: null,
    };
  },
  methods: {
    
  },
  mounted() {
    let u = JSON.parse(localStorage.userData);
    this.name = u.leg_nombre.split(' ')[0];
    this.name = capitalize(this.name);
  }
};
</script>

<style>
.dash-card {
  background-color: #00dbde;
  background-image: linear-gradient(205deg,#00dbde,#98d27a);
  padding: 20px 0;
  color: #fff;
}

.dash-title {
  font-size: 1.6rem;
  margin: 0;
}

.dash-description {
  margin: 0;
  font-size: 1rem;
}
</style>
