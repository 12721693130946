<template>

  <div class="row">
    <div class="col">
      <!-- <h4>Creá y modifica etiquetas gráficas</h4> -->

      <div class="row">
        <div class="col">
          <h4 class="mb-3" v-if="trigger.create">Crear etiquetas</h4>
          <h4 class="mb-3" v-if="trigger.update">Editando etiqueta</h4>
          <form class="">
            <div class="form-row">
              <div class="col-12 mb-2">
                <label class="form-label">Seleccioná un ícono</label>
                <div class="" v-if="icons">
                  <img v-for="(icon,i) in icons" :key="i" :src="icon.path" class="img-thumbnail mr-2 mb-2" :class="{'border-primary':tag.icon === i}" style="width:64px;height:64px" @click.prevent="selectIcon(i)"/>
                </div>
                <div class="text-danger mt-1" v-if="error.icon"> &otimes; {{ error.icon }} </div>
                <button class="btn btn-link p-0" @click.prevent="createNewIcon()">Agregar ícono +</button>
                <div class="mt-2 border border-light p-2 rounded" v-if="trigger.createIcon">
                  <icon-creator @created="getIcons()"></icon-creator>
                </div>
              </div>
              <div class="col-12 mb-2">
                <label class="form-label">Etiqueta por defecto</label>
                <input type="text" class="form-control" placeholder="Ideal para consumir en una porción moderada" v-model="tag.label"/>
                <div class="text-danger mt-1" v-if="error.label"> &otimes; {{ error.label }} </div>
              </div>
              <div class="col-12 mb-2" v-if="trigger.create || trigger.update">
                <label class="form-label">
                  Mostrar imagen con más información
                </label>
                <input-file :multiple="false" :pdf="false" :initial="(!tag.more_info) ? [] : [tag.more_info]" @seleccion="($event.at(0)!=undefined) ? tag.more_info=$event.at(0) : tag.more_info=null" titulo="Seleccionar imagen" @uploading="trigger.imageUploading = $event"></input-file>
              </div>
              <div class="col-12" v-if="error.no_changes">
                <div class="alert alert-primary">No hay cambios que guardar</div>
              </div>
              <div class="col-12 mt-3 text-right">
                <button class="btn btn-success" @click.prevent="create($event)" v-if="trigger.create" :disabled="trigger.imageUploading">Nueva etiqueta gráfica</button>
                <button class="btn btn-success" @click.prevent="update($event)" v-if="trigger.update" :disabled="trigger.imageUploading">Editar etiqueta gráfica</button>
              </div>
            </div>
          </form>
        </div>
        <div class="col">
          <h4 class="mb-3">Etiquetas gráficas</h4>
          <ul class="list-group" v-if="tags!=null && icons!=null">
            <li class="list-group-item d-flex align-items-center justify-content-between" v-for="(tag,t) in tags" :key="t">
              <div class="d-flex align-items-center justify-content-between">
                <img :src="icons[tag.icon].path" class="img-thumbnail mr-2" style="width:48px;height:48px;">
                <div>{{capitalizeR(tag.label)}}</div>
              </div>
              <div class="ml-3" style="min-width:68px;">
                <button class="btn btn-xs btn-primary mr-1 px-2" @click.prevent="selectToUpdate(t)">
                  <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
                </button>
                <button class="btn btn-xs btn-danger px-2" @click.prevent="remove($event,t)">
                  <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></button>
              </div>
            </li>
          </ul>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
/*eslint-disable*/
// import axios from 'axios';
// import { api } from "@/env.js";
import {setProp,capitalize,getDataFrom} from '@/helpers.js';
import firebase from "firebase/app";
import "firebase/database";
import IconCreator from "@/components/admin/plato/forms/IconCreator";
import axios from 'axios';
// import Modal from '@/components/Modal';
import InputFile from '../../../InputFile.vue'

let fireRecetario = firebase.apps.find((a)=>a.name=='fireRecetario');

export default {
    name:'EtiquetasGraficas',
    components:{IconCreator,InputFile},
    data(){
        return {
            tag:{
                label:'',
                icon:null,
                more_info:null,
            },
            icons:null,
            tags:null,
            updateTagId:null,
            error:{
                icon:'',
                label:'',
                no_changes:false,
            },
            trigger:{
              createIcon:false,
              create:true,
              update:false,
              loading:false,
              imageUploading:false
            }
        }
    },
    methods: {
      selectToUpdate(tag_id){
        this.trigger.create = false;
        this.trigger.update = false;
        this.$nextTick(()=>{
          this.tag = JSON.parse(JSON.stringify(this.tags[tag_id]));
          this.updateTagId=tag_id;
          this.trigger.update = true;
        })
      },
      createNewIcon(){
        this.trigger.createIcon = true;
      },
      selectIcon(id){
        this.tag.icon = id;
      },
      getIcons(){
        fetch('https://fs-healthy.firebaseio.com/icons.json').then((response)=> response.json()).then((data)=>{
          this.icons = data;
        });
      },
      getGraphicTags(){
        fetch('https://fs-healthy.firebaseio.com/etiquetas/graficas.json').then((response)=> response.json()).then((data)=>{
          this.tags = data;
        });
      },
      capitalizeR(string){return capitalize(string)},
      seleccionar(tag){
        this.tag = {descripcion:tag.descripcion,caracter:tag.caracter};
        this.editarEtiqueta = tag.fireId
      },
      cancelar(){
        this.editarEtiqueta = false;
        this.tag = {descripcion:'', caracter:''}
      },
      isInvalidForm(){
        let error = false;
        this.error.label = ''
        this.error.icon = ''

        if(this.tag.label == '') {
          error=true;
          this.error.label = 'Ingrese una leyenda por defecto'
        }
        if(this.tag.icon == null) {
          error=true;
          this.error.icon = 'Seleccione un ícono'
        }

        return error;
      },
      create(event){
        if(this.isInvalidForm()) return null;

        this.triggerButton(event,'Creando etiqueta');

        firebase.database().ref('etiquetas/graficas').push({
          label: this.tag.label.trim().toLowerCase(),
          icon: this.tag.icon,
          more_info: this.tag.more_info
        }).then(()=>{
          console.log('%c graphic tag added','color:#0f0');
          this.triggerButton(event,'Nueva etiqueta gráfica');
          this.$nextTick(()=>{
            this.trigger.update = false
            this.trigger.create = false
            this.$nextTick(()=>{
              this.tag = {label:'', icon:null,more_info:null};
              this.trigger.create = true;
              this.getGraphicTags();
              this.$emit('recipes-update')
            })
          })
        })
      },
      update(event){
        this.triggerButton(event,'Actualizando etiqueta y recetas');


        if(
          this.tag.label == this.tags[this.updateTagId].label && 
          this.tag.icon == this.tags[this.updateTagId].icon &&
          this.tag.more_info == this.tags[this.updateTagId].more_info
          ) {
              this.error.no_changes = true
              this.triggerButton(event,'Editar etiqueta gráfica');
              setTimeout(() => {
                this.tag = {label:'', icon:null,more_info:null};
                this.updateTagId=null;
                this.trigger.create = true;
                this.trigger.update = false;
                this.error.no_changes = false
                return null
              }, 2500);
        }



        if(this.isInvalidForm()) return null;

        firebase.database().ref(`etiquetas/graficas/${this.updateTagId}`).update({
          label: this.tag.label.trim().toLowerCase(),
          icon: this.tag.icon,
          more_info: this.tag.more_info
        }).then(()=>{
          console.log('%c graphic tag updated','color:#0f0');

          axios.get('https://recetario-foodservice.firebaseio.com/recetas.json').then((r)=>{
            let recetas = r.data;

            let promises = [];
            Object.keys(recetas).forEach((id)=>{
              if(recetas[id].graphic_tags!=undefined && recetas[id].graphic_tags[this.updateTagId] != undefined){
                let new_recipe_tag = {}
                let old_tag = this.tags[this.updateTagId];
                // Si el label es el generico se reemplaza por el label nuevo
                if(recetas[id].graphic_tags[this.updateTagId].label === old_tag.label){
                  new_recipe_tag['label'] = this.tag.label.trim().toLowerCase()
                }

                // Se comprueba el icono
                if(recetas[id].graphic_tags[this.updateTagId].icon === old_tag.icon){
                  new_recipe_tag['icon'] = this.tag.icon
                }

                // Si se guardo alguna clave (seria raro que no lo hiciera)
                if(Object.keys(new_recipe_tag).length > 0) {
                  promises.push(fireRecetario.database().ref(`/recetas/${id}/graphic_tags/${this.updateTagId}`).update(new_recipe_tag));
                }


              }
            });

            Promise.all(promises).then(()=>{
              this.$nextTick(()=>{
                this.trigger.create = false;
                this.trigger.update = false;

                this.$nextTick(()=>{
                  this.triggerButton(event,'Editar etiqueta gráfica');
                  this.tag = {label:'', icon:null,more_info:null};
                  this.updateTagId=null;
                  this.trigger.create = true;
                  this.trigger.update = false;
                  this.$emit('recipes-update')
                  this.getGraphicTags();
                })
              })
            })
          })




        })
      },
      remove(event,tag_id){
        this.triggerButton(event,'');

        axios.get('https://recetario-foodservice.firebaseio.com/recetas.json').then((r)=>{
            let recetas = r.data;

            let promises = [];
            Object.keys(recetas).forEach((id)=>{
              if(recetas[id].graphic_tags!=undefined && recetas[id].graphic_tags[tag_id] != undefined){
                // Se remueve el nodo
                promises.push(fireRecetario.database().ref(`/recetas/${id}/graphic_tags/${tag_id}`).remove());
              }
            });

            promises.push(firebase.database().ref(`etiquetas/graficas/${tag_id}`).remove())

            Promise.all(promises).then(()=>{
              this.$emit('recipes-update')
              this.getGraphicTags();
            })
          });


      },
      capitalizeR(str){
        return capitalize(str)
      },
      triggerButton(event,label){
        if(!event.target.disabled == true){
          event.target.innerHTML = `
                  <div class="spinner-border" style="width:12px;height:12px" role="status">
                      <span class="sr-only">Loading...</span>
                  </div> ${label}
              `
        } else {
          event.target.innerHTML = label
        }
        
        event.target.disabled = !event.target.disabled;
      }
    },
    mounted(){
      this.getIcons();
      this.getGraphicTags();
    }
};
</script>

<style>
</style>