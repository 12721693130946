export var app = {
  nombre: "healthy",
  dbUrl: 'https://fs-healthy.firebaseio.com/'
};

export var api = {
  posts: `${app.dbUrl}/posts.json`,
  perfiles: `${app.dbUrl}/perfiles.json`,
  platos: "https://recetario-foodservice.firebaseio.com/recetas.json",
  etiquetasNutricionales: "https://fs-healthy.firebaseio.com/etiquetas/nutricionales.json",
  operacionesConsumo: "https://consumos.foodservice.com.ar/api/empresas",
  // noticias: `https://fs-healthy.firebaseio.com/noticias_slide.json`,
  anuncios: `https://fs-healthy.firebaseio.com/anuncios.json`,
  trivias: `https://fs-healthy.firebaseio.com/trivias.json`,
  postCategorias: `https://fs-healthy.firebaseio.com/post_categorias.json`,
  tag_condicion: "https://recetario-foodservice.firebaseio.com/tag_condicion.json",
  tipo_entradas: "https://recetario-foodservice.firebaseio.com/tipo_entradas.json"
};

// En Platos.vue no se estta usando la api por url same origin

/**
 * Devuelve una url que apunta a un nodo o a un grupo de ellos
 * @param {*} api es el nombre de la api a utilizar, generalmente nombres plurales devuelven grupos de un nodo
 * @param {*} parametro el id o dato que se busca, o a traves del que se ordenan los registros
 * @returns 
 */
export function prefabQ(api, parametro) {
  /*
  * Esto de debe reformular mucho. El parámetro api debería estar inmerso en el primer parámetro de la url, el grupo que se busca.
  * Se puede agregar un tipo de ordenamiento (orderBy).
  * Se puede agregar otro parametro de busqueda.
  */
  switch (api) {
    case "post":
      return `https://fs-healthy.firebaseio.com/posts/${parametro}.json`;
    case "plato":
      return `https://recetario-foodservice.firebaseio.com/recetas/${parametro}.json`;
    case "etiqueta":
      return `https://fs-healthy.firebaseio.com/etiquetas/nutricionales/${parametro}.json`;
    case "postsPorCategoria":
      return `https://fs-healthy.firebaseio.com/posts.json?orderBy="categoria"&equalTo="${parametro}"&print=pretty`;
    case "perfil":
      return `${app.dbUrl}/perfiles/${parametro}.json`;
    case "perfilByMail":
      return `${app.dbUrl}/perfiles.json?orderBy="mail"&equalTo="${parametro}"&limitToLast=1&print=pretty`;
    default:
      return "ninguna api encontrada";
  }
}

export var firebaseConfig = {
  // // Desarrollo
  // apiKey: "AIzaSyAf7zdi3EUqdpx1AjlrPF1dLYIXI7sHb_Q",
  // authDomain: "fs-healthy.firebaseapp.com",
  // databaseURL: "https://fs-healthy-default-rtdb.firebaseio.com",
  // projectId: "fs-healthy",
  // storageBucket: "fs-healthy.appspot.com",
  // messagingSenderId: "252567456531",
  // appId: "1:252567456531:web:bf7d9cac528a7e166a68e6"


  // Produccion
  apiKey: "AIzaSyBLyOhPH9YaePUSi5fIMjaavlrQVJ2-UnY",
  authDomain: "cantina-89c6a.firebaseapp.com",
  databaseURL: "https://fs-healthy.firebaseio.com",
  projectId: "cantina-89c6a",
  storageBucket: "cantina-89c6a.appspot.com",
  messagingSenderId: "353062217145",
  appId: "1:353062217145:web:8849a49a1ec52077"


  // Recetario
  // apiKey: "AIzaSyBIdDKcZ5YHXzwG3UOjRiDRzV7vFymuMmo",
  // authDomain: "fs-recetario.firebaseapp.com",
  // projectId: "fs-recetario",
  // storageBucket: "fs-recetario.appspot.com",
  // messagingSenderId: "847772736340",
  // appId: "1:847772736340:web:5222a18850cd6e1d9bc6c1"


};

export var firebaseRecetario = {
  // Prod
  apiKey: "AIzaSyApB2uQlboVr5b77Cvouv_p6B7-lcKhTYo",
  authDomain: "recetario-foodservice.firebaseapp.com",
  databaseURL: "https://recetario-foodservice.firebaseio.com",
  projectId: "recetario-foodservice",
  storageBucket: "recetario-foodservice.appspot.com",
  messagingSenderId: "839765163047",
  appId: "1:839765163047:web:e12263300de6048e"


  // Dev
  // apiKey: "AIzaSyBIdDKcZ5YHXzwG3UOjRiDRzV7vFymuMmo",
  // authDomain: "fs-recetario.firebaseapp.com",
  // databaseURL: "https://fs-recetario-default-rtdb.firebaseio.com",
  // projectId: "fs-recetario",
  // storageBucket: "fs-recetario.appspot.com",
  // messagingSenderId: "847772736340",
  // appId: "1:847772736340:web:5222a18850cd6e1d9bc6c1"

};

export var tableConfig = {
  language: {
    decimal: ",",
    thousands: ".",
    info:
      "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
    infoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
    infoPostFix: "",
    infoFiltered: "(filtrado de un total de _MAX_ registros)",
    loadingRecords: "Cargando...",
    lengthMenu: "Mostrar _MENU_ registros",
    paginate: {
      first: "Primero",
      last: "Último",
      next: "Siguiente",
      previous: "Anterior",
    },
    processing: "Procesando...",
    search: "Buscar:",
    searchPlaceholder: "Término de búsqueda",
    zeroRecords: "No se encontraron resultados",
    emptyTable: "Ningún dato disponible en esta tabla",
    aria: {
      sortAscending: ": Activar para ordenar la columna de manera ascendente",
      sortDescending: ": Activar para ordenar la columna de manera descendente",
    },
  },
  scrollX: false,
  search: { regex: true },
  dom: "Bfrtip",
  responsive: false,
  nowrap: false,
  buttons: ["copy", "excel", "csv", "pdf"],
};
