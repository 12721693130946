<template>
    <div class="row" v-if="trigger.loading">
        <div class="col">
            <div class="w-50 mx-auto my-4 py-4 text-center">
                <h3 class="mb-3">Creando nueva categoria</h3>
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2" v-if="trigger.list">
        <div class="col">
            <div class="mb-2 d-flex align-items-center justify-content-between">
                <h4>Lista de categorias del blog</h4>
                <button class="btn btn-primary" @click.prevent="agregar('create')">Agregar nueva categoria</button>
            </div>
            <table class="table" v-if="categorias && Object.keys(categorias).length">
                <tbody>
                    <tr v-for="(categoria,i) in categorias" :key="i">
                        <td><img :src="categoria.imagen" class="rounded-circle"></td>
                        <td>{{categoria.label}}</td>
                        <td>
                            <button class="btn btn-primary mr-2" @click.prevent="editarCategoria(i)">Editar</button>
                            <button class="btn btn-danger" @click.prevent="handleDelete(i)">Eliminar</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="alert alert-info text-white" v-else>
                <h5>No existen categorias</h5>
                <p>Agregá nuevas categorias para asignar en tus posts</p>
            </div>
        </div>
    </div>
    <div class="row" v-if="trigger.edition">
        <div class="col">
            <div class="mb-2 d-flex align-items-center justify-content-between">
                <h4>Nueva categoria</h4>
                <button class="btn btn-light" @click.prevent="agregar('create')">Cancelar</button>
            </div>
            <div class="form-row mb-3">
                <div class="col-6">
                        <div class="form-label">Nombre</div>
                        <input type="text" class="form-control" v-model="categoria.label" @change="error.label = ''">
                        <div class="text-danger mt-1" v-if="error.label">&otimes; {{error.label}}</div>
                </div>
                <div class="col-6">
                    <div class="form-label">Seleccioná una imagen principal</div>
                    <image-select :error="error.imagen" :titulo="'Imagen'" @seleccion="setCategoryImage"></image-select>
                </div>
            </div>
        </div>
        <div class="col-12 text-right">
            <button type="button" class="btn btn-success" @click.prevent="crear()">Crear nueva categoria</button>
        </div>
  </div>
    <div class="row" v-if="trigger.update">
        <div class="col">
            <div class="mb-2 d-flex align-items-center justify-content-between">
                <h4>Editar categoria</h4>
                <button class="btn btn-light" @click.prevent="agregar('update')">Cancelar</button>
            </div>
            <div class="form-row mb-3">
                <div class="col-6">
                    <div class="form-label">Nombre</div>
                    <input type="text" class="form-control" v-model="categoria.label" @change="error.label = ''">
                    <div class="text-danger mt-1" v-if="error.label">&otimes; {{error.label}}</div>
                </div>
                <div class="col-6">
                    <!-- <div class="w-25 mr-2">
                        <img :src="categoria.imagen" style="width: 100%; height: 50px; border-radius: 6px; object-fit: cover;">
                    </div>
                    <div class="w-75">
                        <div class="form-label">Seleccioná una imagen principal</div>
                        <image-select :error="error.imagen" :titulo="'Imagen'" @seleccion="setCategoryImage"></image-select>
                    </div> -->
                    <div class="form-label">Seleccioná una imagen principal</div>
                    <img :src="selectedImage" v-if="selectedImage && !uploadImage" style="width: 100%; height: 50px; border-radius: 6px; object-fit: cover;margin-bottom:6px">
                    <image-select :error="error.imagen" :titulo="'Imagen'" @seleccion="setCategoryImage" @imagen-removida="uploadImage=false;categoria.imagen=selectedImage"></image-select>
                </div>
            </div>
        </div>
        <div class="col-12 text-right">
            <button type="button" class="btn btn-success" @click.prevent="update()">Actualizar categoria</button>
        </div>
  </div>
</template>

<script>
import ImageSelect from '@/components/admin/ImageSelect'
import {api} from '@/env'
import firebase from "firebase/app";
import "firebase/database";
import Swal from 'sweetalert2'

import { capitalize,almacenarImagen } from "@/helpers.js";
import axios from 'axios';

export default {
    name:"EditarCategorias",
    components: {ImageSelect},
    props:{posts:Object},
    data(){
        return {
            categorias:null,
            categoria:{
                label:null,
                imagen:null
            },
            selected:null,
            selectedImage:null,
            uploadImage:false,
            error: {
                label:'',
                imagen: '',
            },
            trigger:{
                edition:false,
                loading:false,
                list:false,
                update:false,
            }
        }
    },
    methods:{
        handleDelete(id){
            Swal.fire({
                title: 'Vas a borrar una categoria?',
                text: "Cuidado, esto no se puede revertir!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Si, eliminar!'
                }).then((result) => {
                if (result.isConfirmed) {
                    console.log(id);
                    let promises = Object.keys(this.posts).map((post)=>{
                        if(this.posts[post].categoria == this.categorias[id].label){
                            // let n = this.posts[post];
                            // n.categoria = 'Sin asignar';
                            return firebase.database().ref(`posts/${this.posts[post].fireId}`)
                                .update({categoria:'Sin asignar'})
                                .catch((e)=>{alert('Se produjo un error');console.error(e);});
                        }
                    })
                    Promise.all(promises).then(()=>{
                        firebase.database().ref(`post_categorias/${id}`).remove().then(()=>{
                            Swal.fire(
                                'Eliminada!',
                                'La categoria fué eliminada.',
                                'success'
                            );

                            delete this.categorias[id];
                            this.$emit('actualizar-posts');
                        })
                        .catch((e)=>{alert('Se produjo un error');console.error(e);});
                    })
                    
                }
            })
        },
        editarCategoria(i){
            this.selected = i;
            this.categoria.label = this.categorias[i].label;
            this.categoria.imagen = this.categorias[i].imagen;
            this.selectedImage = this.categorias[i].imagen;
            this.error = {
                label:'',
                imagen:''
            }
            this.uploadImage = false;

            this.trigger.list = false;
            this.trigger.update = true;
        },
        agregar(action){
            this.categoria = {
                label:null,
                imagen:null
            } 
            this.error = {
                label:'',
                imagen:''
            }

            if(action == 'update') this.trigger.update = !this.trigger.update;
            if(action == 'create') this.trigger.edition = !this.trigger.edition;
            
            this.trigger.list = !this.trigger.list;
        },
        update(){
            // Es válido hasta que se demuestre lo contrario
            let valido = true;
            Object.keys(this.categoria).forEach((e) => {
                if (this.categoria[e] == '' || this.categoria[e] == null) {
                    this.error[e] = `El campo es obligatorio`;
                    valido = false;
                }
            })

            if (!valido) return null

            this.trigger.update = false
            this.trigger.loading = true
            // Es válido y se prosigue con la carga
            if(this.categoria.imagen instanceof File){
                almacenarImagen(this.categoria.imagen).then((url) => {
                    this.categoria.imagen = url
                    firebase.database().ref(`/post_categorias/${this.selected}`).update(this.categoria).then(()=>{
                        let promises = Object.keys(this.posts).map((post)=>{
                            if(this.posts[post].categoria != this.categorias[this.selected].label) return [];
                                
                            return firebase.database().ref(`posts/${this.posts[post].fireId}`)
                                .update({categoria:this.categoria.label})
                                .catch((e)=>{alert('Se produjo un error');console.error(e);});
                            
                        })
                        Promise.all(promises).then(()=>{

                            // this.trigger.list = true;
                            axios.get(api.postCategorias).then((response)=>{
                                this.categorias = response.data;
                                this.trigger.list = true;
                                this.trigger.loading = false;
                                this.$emit('actualizar-posts');
                            });
                        });
                    })
                })
            }else {
                firebase.database().ref(`/post_categorias/${this.selected}`).update(this.categoria).then(()=>{
                    // this.trigger.list = true;
                    let promises = Object.keys(this.posts).map((post)=>{
                            if(this.posts[post].categoria != this.categorias[this.selected].label) return [];
                                
                            return firebase.database().ref(`posts/${this.posts[post].fireId}`)
                                .update({categoria:this.categoria.label})
                                .catch((e)=>{alert('Se produjo un error');console.error(e);});
                            
                        })
                        Promise.all(promises).then(()=>{

                            // this.trigger.list = true;
                            axios.get(api.postCategorias).then((response)=>{
                                this.categorias = response.data;
                                this.trigger.list = true;
                                this.trigger.loading = false;
                                this.$emit('actualizar-posts');
                            });
                        });
                })
            }
        },
        capitalizeR(string){return capitalize(string)},
        setCategoryImage(image){
            this.categoria.imagen = image
            this.error.imagen = '';
            this.uploadImage = true;
        },
        crear(){
            // Es válido hasta que se demuestre lo contrario
            let valido = true;
            Object.keys(this.categoria).forEach((e) => {
                if (this.categoria[e] == '' || this.categoria[e] == null) {
                    this.error[e] = `El campo es obligatorio`;
                    valido = false;
                }
            })

            if (!valido) return null

            this.trigger.edition = false
            this.trigger.loading = true
            // Es válido y se prosigue con la carga
            almacenarImagen(this.categoria.imagen).then((url) => {
                this.categoria.imagen = url
                firebase.database().ref('/post_categorias').push(this.categoria).then(()=>{
                    this.trigger.loading = false;
                    this.trigger.list = true;
                    axios.get(api.postCategorias).then((response)=>{
                        this.categorias = response.data;
                        this.trigger.list = true;
                        this.trigger.edition = false;
                    });
                    // setTimeout(() => {
                    //     // this.$emit('cerrar',true)
                    // }, 1500);
                })
            })

        },

    },
    mounted(){
        axios.get(api.postCategorias).then((response)=>{
            this.categorias = response.data;
            this.trigger.list = true;
        });
    }
}
</script>

<style>

</style>