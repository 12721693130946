<template>
  <div class="page-content">
    <!-- Breadcrumb -->
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ entidad }}
        </li>
      </ol>
    </nav>
    <!-- Page title -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">{{ entidad }}</h6>
            <div class="d-flex align-items-center">
              <p class="card-description w-75 p-0 m-0">{{ descripcion }}</p>
              <button
                v-on:click="crear()"
                type="button"
                class="btn w-25"
                :class="{
                  'btn-light': trigger.crear,
                  'btn-success': !trigger.crear,
                }"
              >
                {{
                  !trigger.crear
                    ? `Añadir un nuevo anuncio`
                    : "Cancelar la creación"
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Crear -->
     <component-place v-if="trigger.crear">
      <return :align="right" @cerrar="cerrar('crear',false)" class="mb-3"></return>
      <crear @cerrar="cerrar('crear',$event)"></crear>
    </component-place>
    <!-- Ver -->
    <!-- <component-place v-if="trigger.ver">
      <return :align="right" @cerrar="cerrar('ver',false)"></return>
      <ver-post :post="seleccion"></ver-post>
    </component-place> -->
    <!-- Listar -->
    <component-place v-if="trigger.listar">
      <listar :anuncios="anuncios" @editar="manipularPlato('editar',$event)"></listar>
    </component-place>
    <!-- Editar -->
    <!-- <component-place v-if="trigger.editar">
      <return :align="right" @cerrar="cerrar('editar',false)" class="mb-3"></return>
      <editar :platoSeleccionado="seleccion" :entradas="entradas" @cerrar="cerrar('editar',$event)"></editar>
    </component-place> -->

  </div>
</template>

<script>
import { api } from "@/env";
import axios from "axios";

import ComponentPlace from '@/components/admin/ComponentPlace'
import Return from '@/components/admin/ReturnButton'

import Listar from '@/components/admin/cartelera/listaAnuncios.vue'
// import VerPost from '@/components/blog/VerPost'
// import Editar from '@/components/admin/Cartelera/forms/EditarPlato.vue'
import Crear from '@/components/admin/cartelera/forms/CrearAuncio.vue'

export default {
  name: "Plato",
  components: {
    ComponentPlace,
    Return,
    Crear,
    Listar,
  },
  data() {
    return {
      breadcrumbA: "Inicio",
      descripcion: `Lista de todos los anuncios, modificá los anuncios existentes y añadí nuevos`,
      entidad: "anuncio",
      anuncios: [],
      // Para modificacion
      seleccion: null,
      trigger: {
        crear: false,
        editar: false,
        listar: true,
      },
    };
  },
  methods: {
    obtenerEntidades() {
      this.anuncios = [];
      axios.get(api.anuncios).then((response) => {
        if (response.data) {
            this.anuncios = Object.keys(response.data).map((fireId) => {
              // Se agrega el Fireid como propiedad para trabajar luego
              let anuncio = response.data[fireId];
              anuncio.fireId = fireId;
              
              return anuncio;
            });
        }
        this.trigger.listar = true;
      });
    },
    crear() {
      // Si el componente se muestra
      if (!this.trigger.crear) {
        this.trigger.crear = true;
        this.trigger.listar = false;
      }else {
        this.trigger.crear = false;
        this.trigger.listar = true;
      }
    },
    manipularPlato(accion,post){
      this.seleccion = post
      this.trigger.listar = false
      this.trigger[accion] = true
    },
    cerrar(accion,actualizar) {
      this.trigger[accion] = false;
      this.seleccion = null;
      if (actualizar) {
        this.obtenerEntidades();
      } else {
        this.trigger.listar = true;
      }
    },
  },
  mounted() {
    this.obtenerEntidades();
  },
};
</script>

<style></style>