import axios from "axios";
import { api } from "@/env";

export const loadPlatos = async ({ commit }) => {
    try {
        const platos = await axios.get(api.platos)

        if (platos.data && platos.status === 200) {
            const platosArr = Object.keys(platos.data).map((fireId) => {
                let post = platos.data[fireId];
                post.fireId = fireId;
                return post;
            })
            commit('setPlatos', platosArr)
        }

    } catch (err) {
        console.log(err)
    }
}

export const loadEntradas = async ({ commit }) => {
    try {
        const entradas = await axios.get("https://recetario-foodservice.firebaseio.com/tipo_entradas.json")

        if (entradas.data && entradas.status === 200) {
            commit('setEntradas', entradas.data)
        }
    } catch (err) {
        console.log(err)
    }
}