<template>

   <div class="d-flex align-items-end">
      <div class="mr-2">
         <input type="file" accept="image/*" ref="input" @change.prevent="seleccion()" style="height: 28px;"/>
         <div v-if="trigger.loading" class="img-thumbnail loading-thumb">
            <div class="spinner-border text-primary" role="status" v-if="!trigger.success">
               <span class="sr-only">Loading...</span>
            </div>
            <div class="text-success" v-if="trigger.success">
               <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg>
            </div>
         </div>
         <img :src="preview" class="preview-thumb img-thumbnail" v-if="preview && !trigger.loading" @click.prevent="select()">
         <button class="btn btn-primary btn-thumb" v-if="preview==null && !trigger.loading" @click.prevent="select()">
            <svg viewBox="0 0 384 512" style="width: 24px; height: 24px; fill: currentColor;"><path d="M365.3 93.38l-74.63-74.64C278.6 6.742 262.3 0 245.4 0H64C28.65 0 0 28.65 0 64l.0065 384c0 35.34 28.65 64 64 64H320c35.2 0 64-28.8 64-64V138.6C384 121.7 377.3 105.4 365.3 93.38zM336 448c0 8.836-7.164 16-16 16H64.02c-8.838 0-16-7.164-16-16L48 64.13c0-8.836 7.164-16 16-16h160L224 128c0 17.67 14.33 32 32 32h79.1V448zM215.3 292c-4.68 0-9.051 2.34-11.65 6.234L164 357.8l-11.68-17.53C149.7 336.3 145.3 334 140.7 334c-4.682 0-9.053 2.34-11.65 6.234l-46.67 70c-2.865 4.297-3.131 9.82-.6953 14.37C84.09 429.2 88.84 432 93.1 432h196c5.163 0 9.907-2.844 12.34-7.395c2.436-4.551 2.17-10.07-.6953-14.37l-74.67-112C224.4 294.3 220 292 215.3 292zM128 288c17.67 0 32-14.33 32-32S145.7 224 128 224S96 238.3 96 256S110.3 288 128 288z"/></svg>
         </button>
      </div>
      <div class="">
         <div v-if="preview==null">
            <h6 class="">Nuevo ícono</h6>
            <div class="text-muted" :class="{'text-danger':error.dimensions}">· Seleccioná <b>SOLO</b> imágenes cuadradas, mismo ancho y alto.</div>
            <div class="text-muted">· Asigne el mismo nombre para actualizar iconos.</div>
         </div>
         <div v-else>
            <input type="text" class="form-control w-100 mb-1" :class="{'border-danger':error.name}" v-model="iconName" placeholder="Nombre">
            <button class="btn btn-sm btn-primary w-100" @click.prevent="submit()">Guardar</button>
         </div>
      </div>
   </div>
</template>

<script>
import firebase from "firebase/app";
import 'firebase/storage';
import 'firebase/database';

export default {
   name:'IconCreator',
   data() {
      return {
         file:null,
         preview:null,
         error:{dimensions:false,name:false},
         trigger:{loading:false,success:false},
         iconName:null,
      }
   },
   methods:{
      select() {
         this.$refs.input.click();
      },
      createImage(file) {
         return new Promise((resolve)=>{
            let reader = new FileReader()
            reader.onload = (event) => {
                  resolve(event.target.result);
            }
            reader.readAsDataURL(file)
         })
      },
      seleccion(){
         this.file = this.$refs.input.files[0];
         this.createImage(this.$refs.input.files[0]).then((data)=>{
            let img = document.createElement('img');
            img.src = data;
            if(img.height != img.width){
               this.error.dimensions = true;
               return;
            } else {
               this.preview = data;
            }
         })
      },
      submit(){
         if(this.iconName == null || this.iconName.length == 0){
            this.error.name = true;
            return null;
         }

         this.trigger.loading = true;
         this.error.name = false;
         this.error.dimensions = false;

         for (let input of document.querySelectorAll('input,button')) {
            input.disabled = true;
         }

         let name = this.iconName
         .toString()
         .normalize('NFD')
         .replace(/[\u0300-\u036f]/g, '')
         .toLowerCase()
         .trim()
         .replace(/\s+/g, '-')
         .replace(/[^\w-]+/g, '')
         .replace(/--+/g, '-')

         var storageRef = firebase.storage().ref();
         var ref = storageRef.child('healthy/icons/' + name);

         ref.putString(this.preview, 'data_url', {contentType: 'image/png'} ).then((snapshot) => {
            snapshot.ref.getDownloadURL().then((url)=>{
               this.trigger.success = true;

               firebase.database().ref('icons').push({
                  path:url,
                  name:name
               }).then(()=>{
                  setTimeout(() => {
                     this.trigger.loading = false;
                     this.trigger.success = false;
   
                     this.preview = null;
                     this.file = null;
                     this.iconName = null;
   
                     for (let input of document.querySelectorAll('input,button')) {
                        input.disabled = false;
                     }
                  }, 1000);
                  this.$emit('created',url);
               })

            });
         });
      }
   }
}
</script>

<style scoped>
.preview-thumb {
   width: 68px;
   height: 68px;
}

.btn-thumb {
   width: 68px;
   height: 68px;
}

.loading-thumb {
   width: 68px;
   height: 68px;
   display: flex;
   align-items: center;
   justify-content: center;
}
</style>