<template>
    
    <div>
      <h4 class="table-title">Listado de trivias</h4>
      <table class="table" id="tableTrivias">
      <thead>
        <tr>
          <!-- <th></th> -->
          <th>Titulo</th>
          <th>Descripción</th>
          <!-- <th>Vigencia</th> -->
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(trivia,i) in trivias" :key="i">
          <!-- <td><img :src="(trivia.imagen!=undefined)?trivia.imagen:'/assets/images/healthy-preview.png'" class="rounded-circle"></td> -->
          <td>{{capitalizeR(trivia.titulo)}}</td>
          <td>{{capitalizeR(trivia.descripcion)}}</td>
          <!-- <td><div class="badge badge-primary">{{trivia.fecha_fin}}</div></td> -->
          <td>
            <!-- <button class="btn btn-primary mr-2" @click.prevent="$emit('mostrar',trivia)">Vista previa</button> -->
            <button class="btn btn-sm btn-primary mr-2" @click.prevent="$emit('editar',trivia)"><svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg> Editar</button>
            <button class="btn btn-sm btn-danger" @click.prevent="$emit('eliminar',trivia)">
              <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-trash-2"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg> Eliminar</button>
          </td>
        </tr>
        
      </tbody>
    </table>
    </div>
</template>

<script>
// import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
// import axios from 'axios';



import { tableConfig } from "@/env.js";
import { capitalize } from "@/helpers.js";

export default {
  name:'ListaTrivias',
  props:{trivias:Object},
  data() {
    return {
    }
  },
  methods: {
    capitalizeR(string){return capitalize(string)}
  },
  mounted(){
    
    setTimeout(() => {
      $('#tableTrivias').DataTable(tableConfig);
    //     $('#tableTrivias').dataTable().fnDestroy();
    //     this.$nextTick(()=>{
    //     })
      }, 500);
  },
};
</script>

<style>
.rounded-circle {
    width: 20px;
    height: 20px;
    object-fit: cover;
}
</style>