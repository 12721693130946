

export const getPlatos = ( state ) => {
    const data = state.platos
    
    if (!data) return
    return {...data}
}

export const getEntradas = ( state ) => {
    const data = state.entradas
    
    if (!data) return
    return {...data}
}