<template>
  <div class="page-content">
    <!-- Card title section -->
    <div class="row" v-if="!trigger.setAmount">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-md-8">
                <h6 class="card-title">{{ breadcrumbB }}</h6>
                <p class="card-description w-75 p-0 m-0">{{ descripcion }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

	<div class="row" v-if="!operations">
		<div class="col stretch-card">
			<div class="card">
				<div class="card-body">
					<load-row class="w-50 mb-4"></load-row>
          <div class="row mb-3" v-for="(r,i) in 6" :key="i"> 
            <div class="col-2">
              <load-row></load-row>
            </div>
            <div class="col">
              <load-row></load-row>
            </div>
            <div class="col-4">
              <load-row></load-row>
            </div>
          </div>
          <div class="d-flex justify-content-end">
            <load-row class="w-25 mb-3"></load-row>
          </div>
        </div>
			</div>
		</div>
	</div>

	<div class="row" v-if="operations">
		<div class="col stretch-card">
			<div class="card">
				<div class="card-body">
					<h4 class="mb-1">Lista de operaciones</h4>
            <table class="table w-100 table-striped w-100" id="datatable" >
                <thead>
                    <tr>
                        <th>Código</th>
                        <!-- <th>Clave</th> -->
                        <th>Nombre</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(operation,i) in operations" :key="i">
                        <td class="">{{ operation.id }}</td>
                        <!-- <td class="text-center">{{ operation.key }}</td> -->
                        <td class="">{{ operation.name }}</td>
                        <td class="">
                            <button class="btn btn-primary" @click.prevent="generateQR($event,operation)">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 256 256" fill="currentColor"> <g> <path d="M100.00244,36h-44a20.02229,20.02229,0,0,0-20,20v44a20.02229,20.02229,0,0,0,20,20h44a20.02229,20.02229,0,0,0,20-20V56A20.02229,20.02229,0,0,0,100.00244,36Zm-4,60h-36V60h36Z"/> <path d="M100.00244,136h-44a20.02229,20.02229,0,0,0-20,20v44a20.02229,20.02229,0,0,0,20,20h44a20.02229,20.02229,0,0,0,20-20V156A20.02229,20.02229,0,0,0,100.00244,136Zm-4,60h-36V160h36Z"/> <path d="M200.00244,36h-44a20.02229,20.02229,0,0,0-20,20v44a20.02229,20.02229,0,0,0,20,20h44a20.02229,20.02229,0,0,0,20-20V56A20.02229,20.02229,0,0,0,200.00244,36Zm-4,60h-36V60h36Z"/> <path d="M148.00244,184a12.0006,12.0006,0,0,0,12-12V148a12,12,0,0,0-24,0v24A12.0006,12.0006,0,0,0,148.00244,184Z"/> <path d="M208.00244,152h-12v-4a12,12,0,0,0-24,0v48h-24a12,12,0,1,0,0,24h36a12.0006,12.0006,0,0,0,12-12V176h12a12,12,0,0,0,0-24Z"/> </g> </svg>
                                Generar QR
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
			</div>
		</div>
	</div>


  
	


    <modal v-if="trigger.show" :scroll="false">
      <template v-slot:body>
        <div class="mb-2 text-center text-success">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16"> <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0z"/> <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0l7-7z"/> </svg>
            QR Listo
        </div>
        <h4 class="mb-2 text-center">{{selection.name}}</h4>
        
        <img class="qr-image" :src="generated.data">

        <p class="text-center">Este es el link al que apunta el QR:</p>
        <a :href="generated.link" class="text-center d-block" target="_blank" rel="noopener noreferrer">{{generated.link}}</a>
        
        <div class="text-center mt-4">
            <button class="btn btn-light mr-2" @click="trigger.show=false">Cerrar</button>
            <button class="btn btn-success" @click.prevent="getPDF()">
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-file-earmark-arrow-down" viewBox="0 0 16 16"> <path d="M8.5 6.5a.5.5 0 0 0-1 0v3.793L6.354 9.146a.5.5 0 1 0-.708.708l2 2a.5.5 0 0 0 .708 0l2-2a.5.5 0 0 0-.708-.708L8.5 10.293V6.5z"/> <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5v2z"/> </svg>
                Descargar PDF
            </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import {getDataFrom} from '@/helpers';

import Modal from '@/components/Modal.vue';
import LoadRow from '@/components/LoadRow.vue';

import QRCode from 'qrcode'
import { jsPDF } from "jspdf";
import axios from 'axios';
import $ from 'jquery'

export default {
  name: "OperationsData",
  components:{
    Modal,
    LoadRow,
  },

  data(){
    return {
      breadcrumbB: "Operaciones",
      descripcion: 'Configurá la información relacionada a las operaciones, generá QR para ver los menús.',
      selection:null,
      menus:null,
      role:null,
      operations:null,
      generated:null,
      trigger:{
        create:false,
        edit:false,
        list:false,
        show:false
      }
    }
  },

  mounted(){
      axios.get('https://salida.foodservice.com.ar/api/operationSetAll').then(()=>{
          axios.get("https://salida.foodservice.com.ar/api/operation")
          .then((operations) => {
              this.operations = operations.data;

              this.$nextTick(() => {
                  tableConfig.order = [[0, "asc"]];
                  $("#datatable").dataTable(tableConfig);
              });
          }).catch((err) => console.error(err));

      })
  },
  methods:{
      generateQR(e,operation){
            let buttonInitState = e.target.innerHTML;
            e.target.innerHTML= `
                <div class="spinner-border" style="width: 12px; height: 12px;" role="status">
                <span class="sr-only">Loading...</span>
                </div> Generando`;

            e.target.disabled = true;

            let link =`https://healthy.foodservice.com.ar/?comedor=${operation.key}`;
            
            QRCode.toDataURL(link)
            .then(url => {
                this.generated = {data:url,link:link};
                this.selection = operation;
                this.trigger.show = true;
                e.target.innerHTML=buttonInitState;
                e.target.disabled = false;
            })
            .catch(err => {
                console.error(err)
            })
      },
      getPDF(){
        var doc = new jsPDF()

        doc.addImage(this.generated.data, 'JPEG', 15, 48, 180, 160)

        doc.setDrawColor(0)
        doc.setFillColor(153, 209, 117)
        doc.rect(0, 0, 220, 52, 'F')

        
        doc.setFont('helvetica', 'bolditalic')
        doc.setFillColor(255,255,255)
        doc.setTextColor(255)
        
        doc.setFontSize(70)
        doc.text(58, 30, 'Healthy')

        doc.setFontSize(26)
        doc.text(61, 42, 'by Food Service')


        doc.setDrawColor(0)
        doc.setFillColor(153, 209, 117)
        doc.rect(0, 220, 220, 80, 'F')

        doc.setFontSize(40)
        doc.text(55, 250, 'Escanea el QR')
        doc.text(45, 270, 'Para ver el MENÚ')

       doc.setFillColor(153, 209, 117)
        doc.triangle(70, 221, 105, 200, 140, 221, 'F')


        doc.save(`${this.selection.name}_qr` + '.pdf');

        this.trigger.show = false;
        this.generated = null;
        this.selection = null;
      },
    refreshTriggers(active) {
      if(active=='list'){
        Object.keys(this.trigger).forEach((t) => { this.trigger[t] = false });
        this.trigger.list = true;
        this.menu = null;
        this.getMenuByRole(this.role).then(()=>{
          
        }).catch((err)=>{alert('Error al obtener menús');console.error(err);})
      }else {
        Object.keys(this.trigger).forEach((t) => { this.trigger[t] = false });
        if (active != undefined) this.trigger[active] = true;
      }

    },
    handleEvent(action,selection){
      if(action == 'setAmount'){
        if (this.operation) {
          getDataFrom(`dish/menu/${selection.id}/operation/${this.operation.id}`)
          .then((data)=>{
            console.info(data);
            this.selection = data;
            this.refreshTriggers(action);
          }).catch((err)=>{
            console.error(err);
            alert("Ocurrio un error");
          });
        }else{
            this.selection = selection;
            this.refreshTriggers(action);
        }
      }else{
        this.selection = selection;
          this.refreshTriggers(action);
      }
      
    },
    
  }
}

var tableConfig = {
    language: {
        decimal: ",",
        thousands: ".",
        info:
            "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
        infoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
        infoPostFix: "",
        infoFiltered: "(filtrado de un total de _MAX_ registros)",
        loadingRecords: "Cargando...",
        lengthMenu: "Mostrar _MENU_ registros",
        paginate: {
            first: "Primero",
            last: "Último",
            next: "Siguiente",
            previous: "Anterior"
        },
        processing: "Procesando...",
        search: "Buscar:",
        searchPlaceholder: "Término de búsqueda",
        zeroRecords: "No se encontraron resultados",
        emptyTable: "Ningún dato disponible en esta tabla",
        aria: {
            sortAscending:
                ": Activar para ordenar la columna de manera ascendente",
            sortDescending:
                ": Activar para ordenar la columna de manera descendente"
        },
        scrollY: "300px",
        scrollX: true,
        scrollCollapse: true,
        paging: false,
        fixedColumns: {
            left: 1,
            right: 1
        }
    },
    scrollX: false,
    search: { regex: true },
    dom: "Bfrtip",
    responsive: false,
    nowrap: false,
    buttons: ["copy", "excel", "csv", "pdf"]
};
</script>

<style>

.qr-image {
    display:block;
    margin: 0 auto;
    margin-bottom:2rem;
}
</style>