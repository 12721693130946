<template>
  <div class="page-content">
    <!-- Breadcrumb -->
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ entidad }}
        </li>
      </ol>
    </nav>
    <!-- Page title -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">{{ entidad }}</h6>
            <div class="d-flex align-items-center">
              <p class="card-description w-75 p-0 m-0">{{ descripcion }}</p>
              <button
                v-on:click="crear()"
                type="button"
                class="btn w-25"
                :class="{
                  'btn-light': trigger.crear,
                  'btn-success': !trigger.crear,
                }"
              >
                {{
                  !trigger.crear
                    ? `Añadir nueva trivia`
                    : "Cancelar la creación"
                }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Crear -->
     <component-place v-if="trigger.crear">
      <return :align="right" @cerrar="cerrar('crear',false)" class="mb-3"></return>
      <crear @cerrar="cerrar('crear',$event)"></crear>
    </component-place>
    <!-- Editar -->
     <component-place v-if="trigger.editar">
      <return :align="right" @cerrar="cerrar('editar',false)" class="mb-3"></return>
      <editar :selected="seleccion" @cerrar="cerrar('editar',$event)"></editar>
    </component-place>

    <component-place v-if="!trigger.listar && !trivias.length">
      <lista-carga :card="false"></lista-carga>
    </component-place>

    <component-place v-if="trigger.listar">
      <lista-carga v-if="trigger.load" :card="false"></lista-carga>
      <listar :trivias="trivias" @eliminar="handleDelete($event)" @editar="manipularTrivia('editar',$event)"></listar>
    </component-place>
 
  </div>
</template>

<script>
import { api } from "@/env";
import axios from "axios";
import firebase from "firebase/app";
import "firebase/database";
import Swal from 'sweetalert2';

import ComponentPlace from '@/components/admin/ComponentPlace'
import Return from '@/components/admin/ReturnButton'

import Listar from '@/components/admin/trivia/listaTrivias.vue'
// import VerPost from '@/components/blog/VerPost'
// import Editar from '@/components/admin/Cartelera/forms/EditarPlato.vue'
import Crear from '@/components/admin/trivia/forms/CrearTrivia.vue'
import Editar from '@/components/admin/trivia/forms/EditarTrivia.vue'
import ListaCarga from "@/components/admin/ListaCarga.vue";


export default {
  name: "Trivia",
  components: {
    ComponentPlace,
    Return,
    Crear,
    Editar,
    Listar,
    ListaCarga
  },
  data() {
    return {
      breadcrumbA: "Inicio",
      descripcion: `Lista de todas las trivias, modificá las trivias existentes y añadí nuevas`,
      entidad: "trivia",
      trivias: [],
      // Para modificacion
      seleccion: null,
      trigger: {
        crear: false,
        editar: false,
        listar: false,
      },
    };
  },
  methods: {
    handleDelete(trivia){
      console.log(trivia);
      Swal.fire({
        title: '¿Realmente querés hacerlo?',
        text: "Esta acción no se puede deshacer!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          firebase.database().ref(`trivias/${trivia.fireId}`).remove(()=>{
            this.obtenerEntidades();
            Swal.fire(
              'Eliminada!',
              'La trivia ha sido eliminada.',
              'success'
            )
          })

        }
      })
    },
    obtenerEntidades() {
      this.trivias = [];
      axios.get(api.trivias).then((response) => {
        if (response.data) {
            this.trivias = Object.keys(response.data).map((fireId) => {
              // Se agrega el Fireid como propiedad para trabajar luego
              let trivia = response.data[fireId];
              trivia.fireId = fireId;
              
              return trivia;
            });
        }
        this.trigger.listar = true;
      });
    },
    crear() {
      // Si el componente se muestra
      if (!this.trigger.crear) {
        this.trigger.crear = true;
        this.trigger.listar = false;
        this.trigger.editar = false;
      }else {
        this.trigger.crear = false;
        this.trigger.listar = true;
      }
    },
    manipularTrivia(accion,post){
      this.seleccion = post
      this.trigger.listar = false
      this.trigger[accion] = true
    },
    cerrar(accion,actualizar) {
      this.trigger[accion] = false;
      this.seleccion = null;
      if (actualizar) {
        this.obtenerEntidades();
      } else {
        this.trigger.listar = true;
      }
    },
  },
  mounted() {
    this.obtenerEntidades();
  },
};
</script>

<style></style>