<template>
  <nav class="sidebar">
    <div class="sidebar-header">
      <a href="" class="sidebar-brand"> <span>FS</span> HEALTHY</a>
      <div class="sidebar-toggler not-active">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
    <div class="sidebar-body">
      <ul class="nav">
        <li class="nav-item nav-category">Gestión de contenido</li>  
        <li class="nav-item">
          <router-link :to="{ name: 'Blog' }" class="nav-link">
            <i class="link-icon" data-feather="file-text"></i>
            <span class="link-title">Blog</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'Platos' }" class="nav-link">
            <i class="link-icon" data-feather="map"></i>
            <span class="link-title">Platos</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'Anuncios' }" class="nav-link">
            <i class="link-icon" data-feather="bell"></i>
            <span class="link-title">Cartelera</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'Trivias' }" class="nav-link">
            <i class="link-icon" data-feather="award"></i>
            <span class="link-title">Trivias</span>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link :to="{ name: 'OperationsData' }" class="nav-link">
            <i class="link-icon" data-feather="box"></i>
            <span class="link-title">Operaciones QR</span>
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>

// const userPermission = JSON.parse(JSON.parse(localStorage.getItem('userPermisos')));


export default {
  name: 'Menu',
    methods:{
    //  isRoll(modulo){
      
    //     // var status =  userPermission.find( record => record.ModulosAccess === modulo).Lector;
    //     return status;
    //   }
  },
  mounted(){
  }
};


</script>

<style scoped>
.sidebar .sidebar-header .sidebar-brand{
    /* font-size: 1.2rem; */
    padding-top: 4px;
}
</style>
