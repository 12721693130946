<template>
    <div class="row" v-if="trigger.loading">
        <div class="col">
            <div class="w-50 mx-auto my-4 py-4">
                <h3 class="text-center mb-3">Guardando</h3>
                <!-- <div style="height: 4px" class="progress">
                    <div role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width: 40%" class="progress-bar" ref="progressBar"></div>
                </div> -->
                <div class="text-center">
                    <div class="spinner-grow text-primary spinner-grow-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-primary spinner-grow-sm ml-2" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-if="trigger.complete">
        <div class="col">
            <div class="w-50 mx-auto my-4 py-4">
                <h3 class="text-center mb-3 text-success">¡Buen trabajo!</h3>
                <h4 class="text-center mb-3"><div class="badge badge-success">Trivia modificada</div></h4>
            </div>
        </div>
    </div>
  <div class="row" v-if="trigger.edition">
      <div class="col">
          <h4 class="title-place-component">Creando nueva trivia</h4>
          <form>
              <div class="form-row mb-4">
                  <div class="col">
                      <label class="form-label">Título</label>
                      <input type="text" class="form-control" v-model="trivia.titulo">
                      <div class="text-danger mt-1" v-if="error.titulo">{{error.titulo}}</div>
                  </div>
                  <div class="col">
                      <label class="form-label">Descripción</label>
                      <input type="text" class="form-control" v-model="trivia.descripcion" placeholder="">
                      <div class="text-danger mt-1" v-if="error.descripcion">{{error.descripcion}}</div>
                  </div>
                  <!-- <div class="col">
                      <label class="form-label">Fecha finalización</label>
                      <input type="date" class="form-control" v-model="trivia.fecha_fin">
                      <div class="text-danger mt-1" v-if="error.fecha">{{error.fecha}}</div>
                  </div> -->
                  <!-- <div class="col">
                        <label class="form-label w-100">Imagen</label>
                        <div>
                            <image-select :error="error.imagen" :titulo="'Imagen'" @seleccion="setImage"></image-select>
                        </div>
                  </div> -->
              </div>

            <div class="form-row mb-3">
                <div class="col-12 mb-2">
                    <h5>Preguntas de la trivia</h5>
                </div>
                <div class="col-8">
                    <div class="form-label">Enunciado</div>
                    <input type="text" class="form-control" v-model="newQuestionStatement">
                </div>
                <div class="col">
                    <div class="form-label">&nbsp;</div>
                    <button class="btn btn-primary" @click.prevent="addQuestion()">Agregar pregunta</button>
                </div>
            </div>

            <div class="form-row">
                <div class="col-6 mb-2" v-for="(question,q) in trivia.questions" :key="q">
                    <div class="border border-dark p-3 rounded">
                        <h5 class="mb-2">Pregunta número {{q+1}}</h5>
                        <div class="d-flex align-items-end justify-content-between">
                            <div class="w-100 mr-2">
                                <div class="form-label">Enunciado</div>
                                <input type="text" class="form-control w-100 d-block" v-model="question.title" @keyup.enter="removeQuestion(q)">
                            </div>
                            <button class="btn btn-danger w-50" @click.prevent="removeQuestion(q)">Eliminar pregunta</button>
                        </div>
                        <div class="text-danger mt-1" v-if="error.question[q]">&otimes; {{error.question[q]}}</div>
                        <h5 class="mb-2 mt-3" v-if="Object.keys(question.answers).length">Respuestas</h5>
                        <div v-for="(answer,a) in question.answers" :key="a">
                            <div class="d-flex align-items-center justify-content-between mb-2">
                                <div class=" mr-2">
                                    <input type="text" class="form-control w-100 d-block" v-model="answer.label">
                                </div>
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" v-model="answer.correct" :id="answCheck+q+a">
                                    <label class="form-check-label" :for="answCheck+q+a">Correcta</label>
                                </div>
                                <button class="btn btn-danger" @click.prevent="removeQuestionAnswer(q,a)">x</button>
                            </div>
                        </div>
                        <div class="text-danger mt-1" v-if="error.answer[q]">&otimes; {{error.answer[q]}}</div>
                        <div class="btn btn-primary w-100 mt-1" @click.prevent="addQuestionAnswer(q)">Agregar respuesta</div>
                    </div>
                </div>
            </div>


            <div class="form-row">
                <div class="col text-right">
                    <div class="btn btn-md btn-success" @click.prevent="submit">Editar Trivia</div>
                </div>
            </div>
             
          </form>
      </div>
  </div>
</template>
<script>
// import {api} from "@/env.js";
import {isEmpty,almacenarImagen,capitalize} from '@/helpers.js';

// import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";

// import ImageSelect from '@/components/admin/ImageSelect'

export default {
    name: 'EditarTrivia',
    components: { },
    props:{selected:Object},
    data(){
        return {
            trivia: {
                descripcion:'',
                titulo: '',
                questions:[], 
                // fecha_fin: '',
                // imagen:''
            },
            error:{
                descripcion:'',
                titulo: '',
                // fecha_fin: '',
                // imagen:''
                question:{},
                answer:{},
            },
            trigger:{
                edition:true,
                loading:false,
                complete: false,
            },  
            newQuestionStatement:null,
            
        }
    },
    methods:{
        addQuestion(){
            if(!this.newQuestionStatement || !this.newQuestionStatement.length) return null;
            let q = {
                title: capitalize(this.newQuestionStatement.trim()),
                correct_answers:[],
                answers:{},
            }
            this.trivia.questions.push(q);
            this.newQuestionStatement = null;
        },

        removeQuestion(q){
            this.trivia.questions.splice(q,1);
        },

        addQuestionAnswer(qIndx){
            let id = Date.now().toString(32);

            this.trivia.questions[qIndx].answers[id] = {
                label:'',
                correct:false,
            }
        },

        removeQuestionAnswer(q,a){
            delete this.trivia.questions[q].answers[a];
        },

        triviaIsValid(){
            let valid = true;
            this.trivia.questions.forEach((q,i)=>{
                if(this.error.question[i]!=undefined) delete this.error.question[i];
                if (!q.title.length) {
                    // Sin enunciado
                    this.error.question[i] = 'El enunciado es obligatorio';
                    valid = false;
                }

                if (Object.keys(q.answers).length < 2) {
                    // Sin respuestas
                    valid = false;
                    this.error.question[i] = 'Al menos 2 respuestas son obligatorias';
                }



                let checked = false;
                Object.keys(q.answers).forEach((aK)=>{
                    if(this.error.answer[i]!=undefined) delete this.error.answer[i];

                    if (!q.answers[aK].label.length) {
                        // Respuesta vacia
                        valid = false;
                        this.error.answer[i] = 'Ninguna respuesta puede estar vacia';
                    }

                    if(q.answers[aK].correct) checked = true;
                });

                if(!checked){
                    // Sin respuestas correctas
                    this.error.answer[i] = 'Al menos 1 respuesta debe ser correcta';
                    valid = false;
                    console.log('no correct answer in', q);
                }
            });

            return valid;
        },

        submit(){
            if(!this.esValido()) return null;
            if(!this.triviaIsValid()) return null;

            this.trigger.edition = false;
            this.trigger.loading = true;

            firebase.database().ref(`/trivias/${this.selected.fireId}`).update(this.trivia).then(()=>{
                this.trigger.loading = false;
                this.trigger.complete = true;
                setTimeout(() => {
                    this.$emit('cerrar',true)
                }, 1500);
            })
        },



 


        crear(){
            if(!this.esValido()) return null;

            this.trigger.edition = false;
            this.trigger.loading = true;

            almacenarImagen(this.trivia.imagen).then((url) => {
                this.trivia.imagen = url
                // this.nuevoPost.fecha = Date.now()
                firebase.database().ref(`/trivias`).update(this.trivia).then(()=>{
                    this.trigger.loading = false;
                    this.trigger.complete = true;
                    setTimeout(() => {
                        this.$emit('cerrar',true)
                    }, 1500);
                })
            }).catch((e) => {
                console.error(e);
                alert('Se produjo un error subiendo el trivia, podés reintentar pero antes refrescá la página (tecla F5).\n\nContacte con sistemas si el problema persiste');
            })
            
        },
        esValido(){
            let valido = true;
            Object.keys(this.trivia).forEach((prop) => {
                this.error[prop] = '';
                if (isEmpty(this.trivia[prop])) {
                    valido = false;
                    this.error[prop] = 'No puede estar vacio';
                }
            })

            return valido
        },
        setImage(image){
            this.trivia.imagen = image
            this.error.imagen = ''
        },

    },
    mounted(){
        this.trivia = JSON.parse(JSON.stringify(this.selected));
        delete this.trivia.fireId;
    }
}
</script>

<style scoped>
/* .multiselect {
    color: #495057;
    border: 1px solid #282f3ae6;
    border-radius: 0.25rem;
}

.multiselect-option.is-pointed {
    background: var(--ms-option-bg-pointed,#dfeaff);
    color: var(--ms-option-color-pointed,#1f2937);
}

.multiselect-dropdown {
    color: #495057 !important;
    border: 1px solid #282f3ae6 !important;
    border-radius: 0.25rem !important;
    bottom: -7px !important;

} */

.multiselect {
    border-color: #000;
}

.multiselect-dropdown {
    border-color: #000;
}


.btn {
    font-size: 0.8rem;
    line-height: 1.5;
    height: calc(1.5em + 0.75rem + 2px);
    /* padding: 0.375rem 1.75rem 0.375rem 0.75rem; */
}

.form-check {
    margin:0;
}

.form-check input {
    margin-top:0;
}

.form-check label {
    margin-left:.4rem;
}


</style>
