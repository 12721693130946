<template>
    <div class="row" v-if="trigger.loading">
        <div class="col">
            <div class="w-50 mx-auto my-4 py-4">
                <h3 class="text-center mb-3">Creando</h3>
                <!-- <div style="height: 4px" class="progress">
                    <div role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width: 40%" class="progress-bar" ref="progressBar"></div>
                </div> -->
                <div class="text-center">
                    <div class="spinner-grow text-primary spinner-grow-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-primary spinner-grow-sm ml-2" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-if="trigger.complete">
        <div class="col">
            <div class="w-50 mx-auto my-4 py-4">
                <h3 class="text-center mb-3 text-success">¡Buen trabajo!</h3>
                <h4 class="text-center mb-3"><div class="badge badge-success">Anuncio creado</div></h4>
            </div>
        </div>
    </div>
  <div class="row" v-if="trigger.edition">
      <div class="col">
          <h4 class="title-place-component">Creando un nuevo anuncio</h4>
          <form>
              <div class="form-row mb-4">
                  <div class="col">
                      <label class="form-label">Título</label>
                      <input type="text" class="form-control" v-model="anuncio.titulo">
                      <div class="text-danger mt-1" v-if="error.titulo">{{error.titulo}}</div>
                  </div>
                  <div class="col">
                      <label class="form-label">Descripción</label>
                      <input type="text" class="form-control" v-model="anuncio.descripcion" placeholder="">
                      <div class="text-danger mt-1" v-if="error.descripcion">{{error.descripcion}}</div>
                  </div>
                  <div class="col">
                      <label class="form-label">Fecha finalización</label>
                      <input type="date" class="form-control" v-model="anuncio.fecha_fin">
                      <div class="text-danger mt-1" v-if="error.fecha">{{error.fecha}}</div>
                  </div>
                  <div class="col">
                        <label class="form-label w-100">Imagen</label>
                        <div>
                            <image-select :error="error.imagen" :titulo="'Imagen'" @seleccion="setImage"></image-select>
                        </div>
                  </div>
              </div>
              <div class="form-row">
                  <div class="col text-right">
                      <button class="btn btn-light btn-md" @click.prevent="$emit('cerrar',false)">Cancelar</button>
                      <button class="btn btn-success btn-md ml-2" @click.prevent="crear()">Crear anuncio</button>
                  </div>
              </div>
          </form>
      </div>
  </div>
</template>
<script>
// import {api} from "@/env.js";
import {isEmpty,almacenarImagen} from '@/helpers.js';

// import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";

import ImageSelect from '@/components/admin/ImageSelect'

export default {
    name: 'CrearAnuncio',
    components: {
        ImageSelect
    },
    data(){
        return {
            anuncio: {
                descripcion:'',
                titulo: '',
                fecha_fin: '',
                imagen:''
            },
            error:{
                descripcion:'',
                titulo: '',
                fecha_fin: '',
                imagen:''
            },
            trigger:{
                edition:true,
                loading:false,
                complete: false,
            },        
        }
    },
    methods:{
        crear(){
            if(!this.esValido()) return null;

            this.trigger.edition = false;
            this.trigger.loading = true;

            almacenarImagen(this.anuncio.imagen).then((url) => {
                this.anuncio.imagen = url
                // this.nuevoPost.fecha = Date.now()
                firebase.database().ref(`/anuncios`).push(this.anuncio).then(()=>{
                    this.trigger.loading = false;
                    this.trigger.complete = true;
                    setTimeout(() => {
                        this.$emit('cerrar',true)
                    }, 1500);
                })
            }).catch((e) => {
                console.error(e);
                alert('Se produjo un error subiendo el anuncio, podés reintentar pero antes refrescá la página (tecla F5).\n\nContacte con sistemas si el problema persiste');
            })
            
        },
        esValido(){
            let valido = true;
            Object.keys(this.anuncio).forEach((prop) => {
                this.error[prop] = '';
                if (isEmpty(this.anuncio[prop])) {
                    valido = false;
                    this.error[prop] = 'No puede estar vacio';
                }
            })

            return valido
        },
        setImage(image){
            this.anuncio.imagen = image
            this.error.imagen = ''
        },

    },
    mounted(){
        
    }
}
</script>

<style scoped>
/* .multiselect {
    color: #495057;
    border: 1px solid #282f3ae6;
    border-radius: 0.25rem;
}

.multiselect-option.is-pointed {
    background: var(--ms-option-bg-pointed,#dfeaff);
    color: var(--ms-option-color-pointed,#1f2937);
}

.multiselect-dropdown {
    color: #495057 !important;
    border: 1px solid #282f3ae6 !important;
    border-radius: 0.25rem !important;
    bottom: -7px !important;

} */

.multiselect {
    border-color: #000;
}

.multiselect-dropdown {
    border-color: #000;
}



</style>
