<template>
  <div class="page-content">
    <!-- Breadcrumb -->
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ entidad }}
        </li>
      </ol>
    </nav>
    <!-- Page title -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h6 class="card-title">{{ entidad }}</h6>
            <div class="d-flex align-items-center justify-content-between">
              <p class="card-description w-50 p-0 m-0">{{ descripcion }}</p>
              <div>
                <button class="btn btn-primary mr-2" v-if="!trigger.categorias" @click.prevent="trigger.categorias = true">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16"> <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/> <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/> </svg>
                  Categorias
                </button>
                <button class="btn btn-light mr-2" v-else @click.prevent="trigger.categorias = false">
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16"> <path d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z"/> <path d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z"/> </svg>
                  Cerrar categorias
                </button>
                <button v-on:click="crear()" type="button" class="btn" :class="{ 'btn-light': trigger.crear, 'btn-success': !trigger.crear, }" >
                  {{ !trigger.crear ? `Crear post` : "Cancelar la creación" }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <component-place v-if="trigger.categorias">
      <editar-categorias
        :posts="posts"
        @cerrar="cerrar('categorias', $event)"
        @actualizar-posts="obtenerEntidades()"
      ></editar-categorias>
    </component-place>
    <!-- Crear -->
    <component-place v-if="trigger.crear">
      <crear-post @cerrar="cerrar('crear', $event)"></crear-post>
    </component-place>
    <!-- Ver -->
    <component-place v-if="trigger.ver">
      <return :align="right" @cerrar="cerrar('ver', false)"></return>
      <ver-post :post="seleccion"></ver-post>
    </component-place>
    <!-- Listar -->
    <component-place v-if="trigger.listar">
      <listar-posts
        :posts="posts"
        @update="obtenerEntidades()"
        @mostrar="manipularPost('ver', $event)"
        @editar="manipularPost('editar', $event)"
      ></listar-posts>
    </component-place>
    <!-- Editar -->
    <component-place v-if="trigger.editar">
      <return :align="right" @cerrar="cerrar('editar', false)"></return>
      <editar-post
        :post="seleccion"
        @cerrar="cerrar('editar', $event)"
      ></editar-post>
    </component-place>
  </div>
</template>

<script>
import { api } from "@/env";
import axios from "axios";

import ComponentPlace from "@/components/admin/ComponentPlace";
import Return from "@/components/admin/ReturnButton";

import CrearPost from "@/components/admin/blog/forms/CrearPost";
import ListarPosts from "@/components/admin/blog/ListarPosts";
import VerPost from "@/components/admin/blog/VerPost";
import EditarPost from "@/components/admin/blog/forms/EditarPost";

import EditarCategorias from '@/components/admin/blog/forms/Categorias'

export default {
  name: "Blog",
  components: {
    ComponentPlace,
    Return,
    CrearPost,
    ListarPosts,
    VerPost,
    EditarPost,
    EditarCategorias
  },
  data() {
    return {
      breadcrumbA: "Inicio",
      descripcion: `Lista de todos los posts del blog, modificá los posts existentes y crea nuevo contenido`,
      entidad: "blog",
      posts: [],
      // Para modificacion
      seleccion: null,
      trigger: {
        crear: false,
        editar: false,
        listar: true,
        ver: false,
        categorias:false,
      },
      imagenData: {
        title: "",
        url: "",
        explanation: "",
      },
    };
  },
  methods: {
    obtenerEntidades() {
      this.posts = [];
      axios.get(api.posts).then((postsResp) => {
        if (postsResp.data) {
          this.posts = Object.keys(postsResp.data).map((fireId) => {
            // Se agrega el Fireid como propiedad para trabajar luego
            let post = postsResp.data[fireId];
            post.fireId = fireId;
            return post;
          });
        }
        this.trigger.listar = true;
      });
    },
    crear() {
      // Si el componente se muestra
      if (!this.trigger.crear) {
        this.trigger.crear = true;
        this.trigger.listar = false;
      } else {
        this.trigger.crear = false;
        this.trigger.listar = true;
      }
    },
    manipularPost(accion, post) {
      this.trigger.categorias = false;
      this.seleccion = post;
      this.trigger.listar = false;
      this.trigger[accion] = true;
    },
    cerrar(accion, actualizar) {
      this.trigger[accion] = false;
      this.seleccion = null;
      if (actualizar) {
        this.obtenerEntidades();
      } else {
        this.trigger.listar = true;
      }
    },
  },
  mounted() {
    this.obtenerEntidades();
  },
};
</script>

<style></style>