<template>

  <div class="row">
    <div class="col">
      <h4>Creá y modifica las etiquetas nutricionales existentes</h4>
      <form class="mt-2">
        <div class="form-row">
          <div class="col-3">
            <label class="form-label">Etiqueta</label>
            <input type="text" class="form-control" placeholder="Alto en colesterol" v-model="etiqueta.descripcion" />
          </div>
          <div class="col-2">
            <label class="form-label">Seleccioná un carácter</label>
            <select class="form-select" v-model="etiqueta.caracter">
              <option value='' disabled selected>Color</option>
              <option value="rojo">Rojo</option>
              <option value="amarillo">Amarillo</option>
              <option value="verde">Verde</option>
            </select>
            <div class="text-danger mt-1" v-if="error.caracter">
              &otimes; {{ error.caracter }}
            </div>
          </div>
          <div class="col-3" v-if="etiqueta.descripcion && etiqueta.caracter">
              <label class="form-label">Así se ve la etiqueta</label>
              <div class="form-control text-white font-weight-bold border-0" :class="caracterToBg[etiqueta.caracter]">{{capitalizeR(etiqueta.descripcion)}}</div>
          </div>
          <div class="col">
            <br>
            <div class="d-flex" v-if="editarEtiqueta">
              <button class="btn btn-primary mt-2 py-2" @click.prevent="editar()">Editar etiqueta</button>
              <button class="btn btn-light mt-2 py-2 ml-1" @click.prevent="cancelar()">Cancelar</button>
            </div>
            <button class="btn btn-primary mt-2 py-2" @click.prevent="crear()" v-else>Agregar etiqueta</button>
            
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <h5 class="mb-2">Etiquetas existentes</h5>
      <div class="d-flex flex-wrap">
        <div class="font-weight-bold p-2 rounded mr-2 text-white mb-3" :class="caracterToBg[et.caracter]" v-for="(et,i) in etiquetas" :key="i">
          {{et.descripcion}} <button class="ml-1 btn btn-xs btn-light px-1" @click.prevent="seleccionar(et)">Editar</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable*/
import axios from 'axios';
import { api } from "@/env.js";
import {setProp,capitalize} from '@/helpers.js';
import firebase from "firebase/app";
import "firebase/database";
export default {
    name:'EtiquetasNutricionales',
    data(){
        return {
            caracterToBg:{'rojo':'bg-danger','amarillo':'bg-warning','verde':'bg-success'},
            etiquetas: [],
            editarEtiqueta:false,
            etiqueta:{
                descripcion:'',
                caracter:'',
            },
            error:{
                caracter:'',
                descripcion:''
            }
        }
    },
    methods: {
      capitalizeR(string){return capitalize(string)},
      seleccionar(etiqueta){
        this.etiqueta = {descripcion:etiqueta.descripcion,caracter:etiqueta.caracter};
        this.editarEtiqueta = etiqueta.fireId
      },
      cancelar(){
        this.editarEtiqueta = false;
        this.etiqueta = {descripcion:'', caracter:''}
      },
      editar(){
        if (!this.etiqueta.descripcion.length) {
          this.error.descripcion = 'Es obligatorio un nombre'
          return null
        }

        // if (!this.etiqueta.descripcion.length) {
        //   this.error.descripcion = 'Es obligatorio un nombre'
        //   return null
        // }

        if (!this.etiqueta.caracter.length) {
          this.error.caracter = 'El campo es obligatorio'
          return null
        }

        firebase.database().ref(`etiquetas/nutricionales/${this.editarEtiqueta}`).update(this.etiqueta).then(()=>{
          // console.log('etiqueta modificada');
          this.getEtiquetas();
          this.etiqueta = {descripcion:'', caracter:''};
          this.editarEtiqueta = false;
        }).catch(e=>{
          if (e) console.error(e);
        })
      },
      crear(){
        if (!this.etiqueta.descripcion.length) {
          this.error.descripcion = 'Es obligatorio un nombre'
          return null
        }

        // if (!this.etiqueta.descripcion.length) {
        //   this.error.descripcion = 'Es obligatorio un nombre'
        //   return null
        // }

        if (!this.etiqueta.caracter.length) {
          this.error.caracter = 'El campo es obligatorio'
          return null
        }

        firebase.database().ref('etiquetas/nutricionales').push(this.etiqueta).then(()=>{
          // console.log('etiqueta agregada');
          this.getEtiquetas();
          this.etiqueta = {descripcion:'', caracter:''};
        }).catch(e=>{
          if (e) console.error(e);
        })
      },
      getEtiquetas(){
        this.etiquetas = [];
        axios.get(api.etiquetasNutricionales)
        .then((etiquetasRes) => {
          if(etiquetasRes.status == 200 && etiquetasRes.data){
            // console.log(etiquetasRes.data);
            Object.keys(etiquetasRes.data).forEach((e) => {
              let etiqueta = etiquetasRes.data[e];
              setProp(etiqueta,'fireId',e)
              this.etiquetas.push(etiqueta)
            })
          }
        })
      }
    },
    mounted(){
      this.getEtiquetas()
    }
};
</script>

<style>
</style>