admin/<template>
  <lista-carga v-if="trigger.load" :card="false"></lista-carga>
  <div v-else>
    <h4 class="table-title">Listado de posts</h4>
    <table class="table" id="tablePosts">
      <thead>
        <tr>
          <th></th>
          <th>Título</th>
          <th>Categoria</th>
          <th>Fecha</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(post, i) in posts" :key="i">
          <td>
            <img
              style="background-color: #0066ff"
              :src="post.imagen"
              class="rounded-circle"
            />
          </td>
          <td>{{ post.titulo }}</td>
          <td>
            <div class="badge badge-success">
              {{ capitalizeR(post.categoria) }}
            </div>
          </td>
          <td>{{ formatDate(post.fecha) }}</td>
          <td>
            <button
              class="btn btn-primary mr-2"
              @click.prevent="$emit('mostrar', post)"
            >
              Vista previa
            </button>
            <button
              class="btn btn-warning mr-2"
              @click.prevent="$emit('editar', post)"
            >
              Editar
            </button>
            <button class="btn btn-danger" @click.prevent="eliminarPost(post)">
              Eliminar
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Swal from "sweetalert2";
// import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
// import axios from 'axios';
import firebase from "firebase/app";
import "firebase/database";

import ListaCarga from "@/components/admin/ListaCarga.vue";

import { tableConfig } from "@/env.js";
import { capitalize } from "@/helpers.js";

export default {
  name: "ListarPosts",
  components: { ListaCarga },
  props: { posts: Object },
  data() {
    return {
      trigger: { load: true },
    };
  },
  methods: {
    eliminarPost(post) {
      console.log(post);
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "¿Queres borrarlo?",
          text: `El post: ${post.titulo} no se podrá recuperar`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Si, eliminar!",
          cancelButtonText: "No, cancelar!",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            firebase
              .database()
              .ref(`posts/${post.fireId}`)
              .remove()
              .then(() => {
                swalWithBootstrapButtons.fire(
                  "Listo!",
                  "El post fue eliminado.",
                  "success"
                );

                this.$emit("update");
              })
              .catch((err) => {
                console.error(err);
                alert("Ups! se producjo un error al eliminar el post");
              });
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelado",
              "No te preocupes, está todo a salvo",
              "error"
            );
          }
        });
    },
    formatDate(rawDate) {
      let date = new Date(rawDate);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();

      if (month < 10) {
        return `${day}-0${month}-${year}`;
      } else {
        return `${day}-${month}-${year}`;
      }
    },
    capitalizeR(string) {
      return capitalize(string);
    },
  },
  mounted() {
    setTimeout(() => {
      this.trigger.load = false;
      $('#tablePosts').dataTable().fnDestroy();
      this.$nextTick(() => {
        $("#tablePosts").dataTable(tableConfig);
      });
    }, 400);
  },
};
</script>

<style>
.rounded-circle {
  width: 20px;
  height: 20px;
  object-fit: cover;
}
</style>