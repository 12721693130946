<template>
  <div class="page-content">
    <!-- Breadcrumb -->
    <nav class="page-breadcrumb" id="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">{{ breadcrumbA }}</li>
        <li class="breadcrumb-item active" aria-current="page">
          {{ entidad }}
        </li>
      </ol>
    </nav>
    <!-- Page title -->
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="d-flex align-items-center">
            <h4 class="">Recetas</h4>

              <div class="w-100 text-right">
                <button
                  v-on:click="etiquetas()"
                  type="button"
                  class="btn w-25 ml-2"
                  :class="{
                    'btn-light': trigger.etiquetas,
                    'btn-primary': !trigger.etiquetas,
                  }"
                >
                  {{
                    !trigger.etiquetas
                      ? `Etiquetas de color`
                      : "Volver a platos"
                  }}
                </button>
                <button
                  v-on:click="etiquetasGraficas()"
                  type="button"
                  class="btn w-25 ml-2"
                  :class="{
                    'btn-light': trigger.etiquetasGraficas,
                    'btn-primary': !trigger.etiquetasGraficas,
                  }"
                >
                  {{
                    !trigger.etiquetasGraficas
                      ? `Etiquetas Graficas`
                      : "Volver a platos"
                  }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Listar -->
    <component-place v-if="this.trigger.listar && this.entradas && this.platos.length">
      <listar :platos="platos" :entradas="entradas" @mostrar="manipularPlato('ver',$event)" @editar="manipularPlato('editar',$event)"></listar>
    </component-place>
    <!-- Editar -->
    <component-place v-if="trigger.editar">
      <return :align="right" @cerrar="cerrar('editar',false)" class="mb-3"></return>
      <editar :platoSeleccionado="seleccion" :entradas="entradas" @cerrar="cerrar('editar',$event)"></editar>
    </component-place>

     <!-- Editar -->
    <component-place v-if="trigger.etiquetas">
      <!-- <return :align="right" @cerrar="cerrar('editar',false)"></return> -->
      <etiquetas-nutricionales></etiquetas-nutricionales>
    </component-place>
     <!-- Editar -->
    <component-place v-if="trigger.etiquetasGraficas">
      <!-- <return :align="right" @cerrar="cerrar('editar',false)"></return> -->
      <etiquetas-graficas @recipes-update="obtenerEntidades(false)"></etiquetas-graficas>
    </component-place>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import ComponentPlace from '@/components/admin/ComponentPlace'
import Return from '@/components/admin/ReturnButton'

import Listar from '@/components/admin/plato/listarPlatos.vue'
// import VerPost from '@/components/blog/VerPost'
import Editar from '@/components/admin/plato/forms/EditarPlato.vue'
// import Crear from '@/components/admin/plato/forms/CrearPlato.vue'
import EtiquetasNutricionales from '@/components/admin/plato/forms/EtiquetasNutricionales.vue';
import EtiquetasGraficas from '@/components/admin/plato/forms/EtiquetasGraficas.vue';

export default {
  name: "Plato",
  components: {
    ComponentPlace,
    Return,
    // Crear,
    EtiquetasNutricionales,
    EtiquetasGraficas,
    Listar,
    // VerPost,
    Editar
  },
  data() {
    return {
      breadcrumbA: "Inicio",
      descripcion: `Lista de todos los platos del menú, modificá los platos existentes y añadí nuevos`,
      entidad: "plato",
      // Para modificacion
      seleccion: null,
      trigger: {
        // crear: false,
        editar: false,
        listar: true,
        ver:false,
        etiquetas: false,
        etiquetasGraficas: false
      },
    };
  },
  computed: {
    ...mapState('platos', ['platos', 'entradas', ]),
  },
  methods: {
    etiquetas() {
      // Si el componente se muestra
      if (!this.trigger.etiquetas) {
        this.trigger.etiquetas = true;
        this.trigger.listar = false;
        this.trigger.crear = false;
        this.trigger.editar = false;
      }else {
        this.trigger.etiquetas = false;
        this.trigger.listar = true;
      }
    },
    etiquetasGraficas() {
      // Si el componente se muestra
      if (!this.trigger.etiquetasGraficas) {
        this.trigger.etiquetasGraficas = true;
        this.trigger.listar = false;
        this.trigger.crear = false;
        this.trigger.editar = false;
      }else {
        this.trigger.etiquetasGraficas = false;
        this.trigger.listar = true;
      }
    },
    manipularPlato(accion,post){
      this.seleccion = post
      this.trigger.listar = false
      this.trigger[accion] = true
    },
    cerrar(accion,actualizar) {
      this.trigger[accion] = false;
      this.seleccion = null;
      if (actualizar) {
        this.$store.dispatch('platos/loadPlatos');
        this.trigger.listar= true;
      } else {
        this.trigger.listar = true;
      }
    },
  },
};
</script>

<style></style>