<template>
  <div v-if="isLogged()">
    <div class="main-wrapper">
      <Menu/>
      <div class="page-wrapper">
        <Navbar/>
        <router-view></router-view>
      </div>
    </div>
  </div>
  <div v-else id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { firebaseConfig,firebaseRecetario } from "@/env";

import Menu from "./components/admin/layout/Menu.vue";
import Navbar from "./components/admin/layout/Navbar.vue";

import firebase from "firebase/app";
import "firebase/database";
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
/*eslint-disable*/
const fireRecetario = firebase.initializeApp(firebaseRecetario,'fireRecetario');
/*eslint-enable*/


require('../public/assets/css/demo_1/style.css')
require('../public/assets/css/data-table-self-styles.css')


// DataTable: Una vez importado funciona en todas la demás páginas
// require("datatables.net");
// require("datatables.net-buttons/js/buttons.colVis.js")();
// require("datatables.net-buttons/js/buttons.html5.js")();
// require("datatables.net-buttons/js/buttons.flash.js")();
// require("datatables.net-buttons/js/buttons.print.js")();


// const userData = JSON.parse(localStorage.getItem("userData"));

// console.log(userData);

export default {
  name: "app",
  components: {
    Menu,
    Navbar,
  },
  data(){
    return {
      render:false
    }
  },
  methods: {
    isLogged(){
      return localStorage.getItem("userData") != null
    },

  },
  computed: {
    
  },
  mounted() {
    this.$store.dispatch('platos/loadPlatos');
    this.$store.dispatch('platos/loadEntradas');
  },
};
</script>

<style>
/* #loadinga {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: #efefef;
  z-index: 999999999;
} */

#logotipo {
  width: 400px;
  margin-left: 35%;
  margin-top: 10%;
}

/* Loader */
#loader {
    background: #99D175 !important;
}


.border-healthy {
    border-color: #99D175 !important;
}

/* A continuación se pisan los estilos de la plantilla para marcar los links activos */

.sidebar .sidebar-body .nav .nav-item.active .nav-link::before {
  content: none;
}
.sidebar .sidebar-body .nav .nav-item.active .nav-link{
  color: #000;
}
.sidebar .sidebar-body .nav .nav-item.active .nav-link .link-icon {
  color: #000;
}

/* datatable */
.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background-color: #727cf5 !important;
    color: #fff !important;
}

/* Titulo en tarjetas con boton de retorno */

.title-place-component {
  position: absolute;
  top: -45px;
}

/* Form group addn form bootstrap */

.input-group-text {
    border-color: #282f3ae6 !important;
    border-radius: 0 .25rem .25rem 0 !important;
    background: #ececec !important;
    color: #282f3ae6 !important;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    color: #fff !important;
}

/* Luego se agregan propiedades similares a las pisadas para que Vue marque los activos mediante el router */

.router-link-exact-active{
  /* font-weight: 700; */
  color: #727cf5 !important;
}

.router-link-exact-active .link-icon{
  /* font-weight: 700; */
  color: #727cf5 !important;
}

.router-link-exact-active::before {
  content: '';
  width: 3px;
  height: 26px;
  background: #727cf5;
  position: absolute;
  left: -25px;
}

/* Quill styles */
.ql-toolbar.ql-snow{
  border-radius: 6px 6px 0 0;
  border: 1px solid #2d2d2d !important;
  border-bottom: 0 !important
}



.section-heading .title {
    margin: 0;
    font-size: 18px;
    letter-spacing: -0.01em;
    line-height: 1em;
    padding-right: 16px;
}


/* quitar Feclas de input number */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* 
@media (min-width: 768px) { 
  #app {
    width: 800px;
    margin: 0 auto;
  }

  .card-img-top {
    height: 230px !important;
    object-fit: cover !important;
  }
 }

 .goBack {
   cursor:pointer;
 } */



</style>