<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container" :class="{'overflow-scroll':scroll,'w-80':maxWidth, 'bg-white shadow':background}">
          <div class="modal-body">
            <slot name="body">
              default body
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
    name:"Modal",
    props:{scroll:Boolean,maxWidth:{default:false,type:Boolean},background:Boolean},
}
</script>

<style>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 91%;
  margin: 0px auto;
  padding: 20px 30px;
 
  border-radius: 6px;
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, .33); */
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;

  max-height: 90vh;
  /* overflow: scroll; */
}

@media (min-width: 768px) {
  .modal-container {
    width: 520px;
  }
}

.w-80 {
  width:80%;
}

/* .modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
} */

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.overflow-scroll{
  overflow: scroll;
}
</style>