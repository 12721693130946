<template>
    <div class="row" v-if="trigger.loading">
        <div class="col">
            <div class="w-50 mx-auto my-4 py-4">
                <h3 class="text-center mb-3">Editando</h3>
                <!-- <div style="height: 4px" class="progress">
                    <div role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style="width: 40%" class="progress-bar" ref="progressBar"></div>
                </div> -->
                <div class="text-center">
                    <div class="spinner-grow text-primary spinner-grow-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <div class="spinner-grow text-primary spinner-grow-sm ml-2" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-if="trigger.complete">
        <div class="col">
            <div class="w-50 mx-auto my-4 py-4">
                <h3 class="text-center mb-3 text-success">¡Buen trabajo!</h3>
                <h4 class="text-center mb-3"><div class="badge badge-success">Plato modificado correctamente</div></h4>
            </div>
        </div>
    </div>
  <div class="row" v-if="trigger.edition">
      <div class="col">
          <h4 class="title-place-component ">Editando un plato</h4>
          <form>
              <div class="form-row mb-4">
                  <div class="col-2">
                      <label class="form-label">Código</label>
                      <input type="text" class="form-control" v-model="plato.codigo" disabled>
                      <div class="text-danger mt-1" v-if="error.codigo">{{error.codigo}}</div>
                  </div>
                  <div class="col-4">
                      <label class="form-label">Título del plato</label>
                      <input type="text" class="form-control" v-model="plato.titulo" placeholder="Nombre del plato" disabled>
                      <div class="text-danger mt-1" v-if="error.titulo">{{error.titulo}}</div>
                  </div>
                  <!-- <div class="col">
                        <label class="form-label">Clasificación alimenticia</label>
                        <select class="form-select" v-model="plato.clasificacion" disabled>
                            <option value='' disabled selected>Tipo de plato</option>
                            <option value="rojo">Veggie</option>
                            <option value="amarillo">Vegano</option>
                            <option value="verde">Sin gluten</option>
                        </select>
                        <div class="text-danger mt-1" v-if="error.clasificacion">{{error.clasificacion}}</div>
                  </div> -->
                  <div class="col">
                    <label class="form-label">Mostrar en la APP</label>
                    <div class="mt-1">
                        <label for="healthy_show">
                            <input type="checkbox" id="healthy_show" v-model="plato.healthy_show">
                            Mostrar
                        </label>
                    </div>
                  </div>
                  <div class="col">
                      <label class="form-label">Kilocalorias</label>
                      <!-- <input type="text" class="form-control" v-model="plato.kcal" placeholder="260"> -->
                      <div class="input-group">
                        <input type="text" class="form-control" v-model="plato.kcal" placeholder="000">
                            <div class="input-group-append">
                                <span class="input-group-text">Kcal</span>
                            </div>
                        </div>
                        <div class="text-danger mt-1" v-if="error.kcal">{{error.kcal}}</div>
                  </div>
              </div>
              
              <div class="form-row mb-4">
                  <div class="col">
                    <label class="form-label">Tipo del plato</label>
                    <multiselect
                    disabled
                        :key="updateMultiselectTipo"
                        v-model="plato.entrada"
                        mode="tags"
                        :max="3"
                        :searchable="true"
                        :createTag="false"
                        :options="adaptadorEntradasValueLabel()"
                    >
                         <template v-slot:tag="{ option }">
                            <div class="multiselect-tag is-user bg-light px-2 text-dark font-weight-normal">
                                {{ option.label }}
                            </div>
                        </template>
                    </multiselect>
                    <div class="text-danger mt-1" v-if="error.tipo">{{error.tipo}}</div>
                  </div>
                  <div class="col">
                      <label class="form-label">Etiquetas nutricionales</label>
                      <!-- <input type="text" class="form-control" v-model="plato.etiquetas_nutricionales"> -->
                        <multiselect
                            v-model="plato.etiquetas_nutricionales"
                            mode="tags"
                            :searchable="true"
                            :createTag="false"
                            :trackBy="descripcion"
                            :options="transformEtiquetasNutri()"
                        >
                            <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                <div class="multiselect-tag is-user" :class="'bg-'+option.color">
                                    <!-- <img :src="option.color"> -->
                                    {{ option.label }}
                                    <div class="btn btn-xs mx-1"
                                    :class="'btn-'+option.color"
                                    v-if="!disabled"
                                    @click.prevent
                                    @mousedown.prevent.stop="handleTagRemove(option, $event)"
                                    >✕</div>
                                </div>
                            </template>
                        </multiselect>
                        <div class="text-danger mt-1" v-if="error.etiquetas_nutricionales">{{error.etiquetas_nutricionales}}</div>
                  </div>
              </div>
              <div class="form-row mb-4">
                    <div class="col-6">
                        <label class="form-label w-100">Imagen del plato</label>
                        <div class="form-group form-check d-flex align-items-center pl-4" v-if="plato.imagen">
                            <input type="checkbox" class="form-check-input mt-0" v-model="trigger.changeImage">
                            <label class="form-check-label d-flex align-items-center mb-0 ml-1">
                                <img :src="plato.imagen" class="rounded-square mr-2" v-if="!trigger.changeImage">
                                <p class="mb-0">Reemplazar la imagen existente</p>
                            </label>
                        </div>
                        <div v-if="trigger.changeImage || !plato.imagen">
                            <image-select :error="error.imagen" :titulo="'Imagen'" @seleccion="setImage"></image-select>
                        </div>
                    </div>
                  <div class="col">
                    <label class="form-label w-100">Descripción nutricional del plato</label>
                    <textarea class="form-control w-100" rows="4" v-model="plato.descripcion_nutricional" placeholder="Resalta ingredientes con propiedades nutricionales buenas"></textarea>
                    <div class="text-danger mt-1" v-if="error.descripcion_nutricional">{{error.descripcion_nutricional}}</div>
                  </div>
              </div>
              <div class="form-row mb-5">
                <div class="col-12" v-if="graphicTags!=null">
                    <h5 class="mb-2">Etiquetas gráficas</h5>
                    <div class="d-flex mb-2">
                        <img 
                            :src="tag.icon_path" 
                            class="img-thumbnail mr-2 mb-2" 
                            :class="{'border-primary':checkGraphicTahIsUsed(t)}" 
                            v-for="(tag,t) in graphicTags" :key="t"
                            @click.prevent="selectGraphicTag(t)"
                            style="width:48px;height:48px"
                            >
                    </div>
                    <div v-if="Object.keys(plato.graphic_tags).length>0">
                        <h6>Etiquetas gráficas seleccionadas</h6>
                        <p>Puede ingresar una descripción de etiqueta que solo afectara a esta receta.</p>

                        <ul class="list-group mt-2">
                            <li class="list-group-item d-flex align-items-center justify-content-between" v-for="(tag,t) in plato.graphic_tags" :key="t">
                                <div class="d-flex align-items-center justify-content-between w-75">
                                    <img :src="tag.icon_path" class="img-thumbnail mr-2" style="width:48px;height:48px;">
                                    <input type="text" class="form-control" v-model="tag.label">
                                </div>
                                <div>
                                    <button class="btn btn-xs btn-danger px-2" @click.prevent="selectGraphicTag(t)">
                                    <svg viewBox="0 0 24 24" width="14" height="14" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><polyline points="3 6 5 6 21 6"></polyline><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path><line x1="10" y1="11" x2="10" y2="17"></line><line x1="14" y1="11" x2="14" y2="17"></line></svg></button>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
              </div>
              <div class="form-row">
                  <div class="col text-right">
                      <button class="btn btn-light btn-md" @click.prevent="$emit('cerrar',false)">Cancelar</button>
                      <button class="btn btn-success btn-md ml-2" @click.prevent="editar()">Editar receta</button>
                  </div>
              </div>
          </form>
      </div>
  </div>
</template>

<style src="@vueform/multiselect/themes/default.css"></style>

<script>
import {api} from "@/env.js";
import {setProp,capitalize,isNum,isEmpty,almacenarImagen} from '@/helpers.js';

import axios from 'axios';
/*eslint-disable*/
import firebase from "firebase/app";
import "firebase/database";
/*eslint-enable*/

let fireRecetario = firebase.apps.find((a)=>a.name=='fireRecetario');

import Multiselect from '@vueform/multiselect';
import ImageSelect from '@/components/admin/ImageSelect';

export default {
    name: 'EditarPlato',
    props: {platoSeleccionado:Object,entradas:Object},
    components: {
        Multiselect,ImageSelect
    },
    data(){
        return {
            etiquetasNutricionales:[],
            plato: {
                titulo:'',
                descripcion_nutricional: '',
                etiquetas_nutricionales: [],
                codigo: '',
                clasificacion:'',
                entrada:[],
                kcal:'',
                imagen:'',
                graphic_tags:{},
                healthy_show:false,
            },
            error:{
                imagen: '',
                descripcion:'',
                descripcion_nutricional: '',
                etiquetas_nutricionales: '',
                codigo: '',
                clasificacion:'',
                entrada:'',
                kcal:'',
            },
            seleccion:{
                etiquetas_nutricionales:[],
                tipo:[]
            },
            graphicTags:null,
            trigger:{
                changeImage:false,
                edition:true,
                loading:false,
                complete: false,
            },
            updateMultiselectTipo: 0,
        
        }
    },
    computed: {
        seleccionEtiquetas(){
            return this.seleccion.etiquetas_nutricionales;
        },
        seleccionTipo(){
            return this.seleccion.tipo;
        }
    },
    watch:{
        seleccionEtiquetas(newVal){
            this.nuevoIndice.variables.var_1_descripcion = this.capitalizeR(`${this.nuevoIndice.formato} de ${newVal}`);
        },
        seleccionTipo(newVal){
            if(this.seleccion.tipo.length > 1) this.seleccion.tipo.shift()
            this.plato.tipo = newVal[0]
            // this.nuevoIndice.variables.var_2_descripcion = this.capitalizeR(`${this.nuevoIndice.formato} de ${newVal}`);
        }
    },
    methods:{
        checkGraphicTahIsUsed(tag_id){
            return Object.keys(this.plato.graphic_tags).some((t)=>this.plato.graphic_tags[t].id==tag_id);
        },
        selectGraphicTag(tag_id){
            // console.log(tag_id);

            // console.log('plato',this.plato.graphic_tags,'id',tag_id);

            // let key = null;
            // Object.keys(this.plato.graphic_tags).some((t)=>{
            //     if(this.plato.graphic_tags[t].id==tag_id){
            //         key=t;
            //         return true; // Break some loop
            //     }
            // });

            if(this.plato.graphic_tags[tag_id] != undefined) {
                delete this.plato.graphic_tags[tag_id]
            }else {
                this.plato.graphic_tags[Date.now()] = this.graphicTags[tag_id]
            }
        },
        transformEtiquetasNutri(){
            const bootsColor = [];
            bootsColor['amarillo'] = 'warning';
            bootsColor['rojo'] = 'danger';
            bootsColor['verde'] = 'success';
            
            return Object.keys(this.etiquetasNutricionales).map((el) => {
                return {
                    'label': capitalize(this.etiquetasNutricionales[el].descripcion),
                    'value': this.etiquetasNutricionales[el].fireId,
                    'color': bootsColor[this.etiquetasNutricionales[el].caracter]
                }
            })
        },
        getEtiquetas(){
            return new Promise((resolve,reject)=>{
                this.etiquetasNutricionales = [];
                axios.get(api.etiquetasNutricionales)
                .then((etiquetasRes) => {
                    if(etiquetasRes.status == 200 && etiquetasRes.data){
                        Object.keys(etiquetasRes.data).forEach((e) => {
                            let etiqueta = etiquetasRes.data[e];
                            setProp(etiqueta,'fireId',e)
                            this.etiquetasNutricionales.push(etiqueta)
                        })

                        resolve()
                    }
                })
                .catch((e)=>{
                    reject(e);
                })
            })
        },
        editar(){
            if(!this.esValido()) return null;

            this.trigger.edition = false;
            this.trigger.loading = true;

            if(this.trigger.changeImage){
                almacenarImagen(this.plato.imagen).then((url) => {
                    this.plato.imagen = url
                    // this.nuevoPost.fecha = Date.now()
                    fireRecetario.database().ref(`/recetas/${this.platoSeleccionado.fireId}`).update(this.plato).then(()=>{
                        this.trigger.loading = false;
                        this.trigger.complete = true;
                        setTimeout(() => {
                            this.$emit('cerrar',true)
                        }, 1500);
                    });
                });
            }else {
                delete this.plato.titulo;
                delete this.plato.codigo;
                delete this.plato.clasificacion;
                delete this.plato.entrada;

                let graphic_tags = Object.keys(this.plato.graphic_tags);
                if(graphic_tags.length > 0){
                    graphic_tags.forEach((g)=>{
                        delete this.plato.graphic_tags[g].icon_path;
                    })
                } else {
                    this.plato.graphic_tags = null
                }



                fireRecetario.database().ref(`/recetas/${this.platoSeleccionado.fireId}`).update(this.plato).then(()=>{
                    this.trigger.loading = false;
                    this.trigger.complete = true;
                    setTimeout(() => {
                        this.$emit('cerrar',true)
                    }, 1500);
                })
            }
            
        },
        esValido(){
            let noValidar = {'titulo':true, 'codigo':true, 'clasificacion':true, 'entrada':true,'descripcion_nutricional':true};
            let valido = true;
            Object.keys(this.plato).forEach((prop) => {
                if(noValidar[prop]!=undefined) return
                
                this.error[prop] = '';
                if (isEmpty(this.plato[prop])) {
                    valido = false;
                    this.error[prop] = 'No puede estar vacio';
                } else if(prop=='kcal' && !isNum(this.plato[prop])){
                    valido = false;
                    this.error[prop] = 'Solo números, sin coma ni punto';
                }
            })

            return valido
        },
        setImage(image){
            this.plato.imagen = image
            this.error.imagen = ''
        },
        /**
         * Devuelve el objecto dado en la forma de clave valor esperado. Por ejemplo {id,nombre} return {value,label}
         */
        adaptadorEntradasValueLabel(){
            return Object.keys(this.entradas).map((e) => {
                return {
                    value:e,
                    label:this.entradas[e].nombre
                }
            })
        },
        capitalizeR(str){
            return capitalize(str)
        }

    },
    mounted(){
        // Se setean las propiedades existentes
        Object.keys(this.platoSeleccionado).forEach((prop) => {
            // Si existe la prop del platoSeleccionado en plato, entonces se setea
            if(this.plato[prop] != undefined) this.plato[prop] = this.platoSeleccionado[prop];

            if (prop == 'tipo') this.seleccion.tipo.push(this.platoSeleccionado[prop])
        })

        this.updateMultiselectTipo++;

        this.getEtiquetas().then(()=>{
            if(this.platoSeleccionado.etiquetas_nutricionales != undefined){
                this.platoSeleccionado.etiquetas_nutricionales.forEach((etiqueta) => {
                    console.log(etiqueta);
                })
            }

            axios.get('https://fs-healthy.firebaseio.com/etiquetas/graficas.json').then((r)=>{
                this.graphicTags = r.data;

                axios.get('https://fs-healthy.firebaseio.com/icons.json').then((rr)=>{
                    Object.keys(this.graphicTags).forEach((t)=>{
                        this.graphicTags[t]['icon_path'] = rr.data[this.graphicTags[t].icon].path; 
                        this.graphicTags[t]['id'] = t; 
                    });

                    let graphic_tags = Object.keys(this.plato.graphic_tags);
                    if(graphic_tags.length > 0){
                        graphic_tags.forEach((g)=>{
                            this.plato.graphic_tags[g]['icon_path'] = this.graphicTags[this.plato.graphic_tags[g].id].icon_path
                        })
                    }
                });
            })
        })
        .catch((e) => {
            console.error(e);
            alert('Se produjo un error obteniendo las etiquetas nutricionales, podés reintentar pero antes refrescá la página (tecla F5).\n\nContacte a sistemas si el problema persiste')
        })
    }
}
</script>

<style scoped>
/* .multiselect {
    color: #495057;
    border: 1px solid #282f3ae6;
    border-radius: 0.25rem;
}

.multiselect-option.is-pointed {
    background: var(--ms-option-bg-pointed,#dfeaff);
    color: var(--ms-option-color-pointed,#1f2937);
}

.multiselect-dropdown {
    color: #495057 !important;
    border: 1px solid #282f3ae6 !important;
    border-radius: 0.25rem !important;
    bottom: -7px !important;

} */

.multiselect {
    border-color: #000;
}

.multiselect-dropdown {
    border-color: #000;
}



  .multiselect-tag.is-user {
    padding: 5px 8px;
    border-radius: 22px;
    background: #cdd0d8;
    margin: 3px 3px 8px;
  }

  /* .tag-text-dark */

  .multiselect-tag.is-user img {
    width: 24px;
    border-radius: 50%;
    height: 24px;
    margin-right: 8px;
  }

  .multiselect-tag.is-user i:before {
    color: #ffffff;
    border-radius: 50%;;
  }

  .user-image {
    margin: 0 6px 0 0;
    border-radius: 50%;
    height: 22px;
  }



</style>
