<template>
  <div>
    <QuillEditor :options="options" v-model:content="content" @ready="getQuill" @editorChange="upData" :key="updateQuill" style="height: 250px; border: 1px solid #151515;border-radius: 0 0 6px 6px;"/>
    <div class="row mt-3">
      <div class="col text-right">
        <!-- <button type="button" class="btn btn-success" @click.prevent="show()">Ver</button> -->
        <!-- <button type="button" class="btn btn-success" @click.prevent="up()">Crear nuevo post</button> -->
      </div>
    </div>
  </div>


  <!-- <div v-html="post.contenido"></div> -->
  <!-- <input type="file" @change="uploadFunction" id="file" hidden>

    <quill-editor 
        v-model="model" 
        :options="editorOption" 
        ref="quillEdit">
    </quill-editor> -->
</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
// import Quill from "quill";

// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
// import { quillEditor } from "vue-quill-editor";
/*eslint-disable*/
// import ImageResize from "quill-image-resize-module";

// import firebase from "firebase/app";
// import "firebase/database";



// https://stackoverflow.com/questions/43857373/quill-editor-vuejs2-image-upload-base64-image-to-url
export default {
  components: {
    QuillEditor
  },
  props:{edit:Object},
  mounted(){
    if(this.edit!=undefined){
      // this.quill.editor.delta = this.edit
      this.content = this.edit
      // this.quill.setText(this.edit)
      // console.log(this.edit);
      this.updateQuill++
    }
    // axios.get('https://fs-healthy-default-rtdb.firebaseio.com/blog/posts/-MeejNdmP8w-H5Kj22Ea.json?print=pretty')
    // .then(r=>{
    //   if (r.data) {
    //     this.post = r.data
    //     console.log(this.post);
    //     this.content = this.post.delta
    //     // this.quill.setText(this.post.delta)
    //     // this.content = this.post.delta
    //     this.see = true
    //   }
    // })
  },
  data() {
    return {
        // model: '',
        // selectedFile : '',
        // editorOption: {
        //     // some quill options
        //     modules: {
        //       toolbar: {
        //         container: [["bold", "image"]],
        //         handlers: {
        //           image: function() {
        //             document.getElementById('file').click()
        //           }
        //         }
        //       },
        //       imageResize: {
        //         modules: ["Resize", "DisplaySize", "Toolbar"]
        //       }
        //     }
          
        see:false,
        post:null,
        // Barra linda que armé
        content: '',
        updateQuill:0,
        // toolbar: [ ['bold', 'italic', 'underline', 'strike'],
        //   [{ 'header': 1 }, { 'header': 2 }],
        //   [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        //   [{ 'align': [] }],
        //   ['image'],  
        //   ['clean']
        //   ],
      options: {
        modules: {
          toolbar: [ ['bold', 'italic', 'underline', 'strike'],
          [{ 'header': [false, 1, 2, 3] }],
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'align': [] }],
          ['blockquote'],
          ['image'],  
          ['clean'],
          ['video']
          ]
        },
        // placeholder: 'Compose an epic...',
        theme: 'snow'
      }
    }
  },

  methods: {
    getQuill(editor){
      this.quill = editor
    },
    upData(){
      // console.log(this.quill);
      this.$emit('change',{delta:this.quill.editor.delta,html:this.quill.root.innerHTML})
      // console.log('se updatio');
    },
    show(){
        // console.log(this.content);

        this.content.forEach((e)=>{
          if ((e.insert.image != undefined)) {
            e.insert.image = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdxNMbsaxQi7nY9Cey87h2Xl3c9RovjwNBYQ&usqp=CAU'
            // console.log(e.insert.image);
          }
        })

        // console.log(this.quill.root.innerHTML);
    },
    up(){
      // this.content.forEach((e)=>{
      //     if ((e.insert.image != undefined)) {
      //       e.insert.image = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdxNMbsaxQi7nY9Cey87h2Xl3c9RovjwNBYQ&usqp=CAU'
      //       // console.log(e.insert.image);
      //     }
      //   })

      firebase.database().ref('blog/posts').push({
        delta:this.quill.editor.delta,
        contenido: this.quill.root.innerHTML,
        titulo:'una prueba',
      })
    },
    // todoLiso(el){
    //     console.log(el.target);
    // },
    // getHTML(){},
    uploadFunction(e){
        this.selectedFile = e.target.files[0];

        var form = new FormData();
        form.append("file", this.selectedFile);
        form.append("name", this.selectedFile.name);

        // //upload image to server
        // axios.post('media-save', form,{
        //     'headers': {
        //     'Content-Type': "multipart/form-data"
        // }
        // })
        // .then(r => {
        // console.log('success')

        let r = 'esro'

        //this code to set your position cursor
        const range = this.$refs.quillEdit.quill.getSelection()
        //this code to set image on your server to quill editor
        this.$refs.quillEdit.quill.insertEmbed(range.index , 'image', `http://your.api/${r}`)
        
    


  }
}}
</script>

<style scoped>
.ql-toolbar.ql-snow{
  height: 400px !important;
  border: 1px solid #2d2d2d !important;
}
</style>

