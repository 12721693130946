<template>
    <lista-carga v-if="trigger.load" :card="false"></lista-carga>
    <div v-else>
      <h4 class="table-title">Listado de anuncios</h4>
      <table class="table" id="tableAnuncios">
      <thead>
        <tr>
          <th></th>
          <th>Titulo</th>
          <th>Descripción</th>
          <th>Vigencia</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(anuncio,i) in anuncios" :key="i">
          <td><img :src="(anuncio.imagen!=undefined)?anuncio.imagen:'/assets/images/healthy-preview.png'" class="rounded-circle"></td>
          <td>{{capitalizeR(anuncio.titulo)}}</td>
          <td>{{capitalizeR(anuncio.descripcion)}}</td>
          <td><div class="badge badge-primary">{{anuncio.fecha_fin}}</div></td>
          <td>
            <!-- <button class="btn btn-primary mr-2" @click.prevent="$emit('mostrar',anuncio)">Vista previa</button> -->
            <button class="btn btn-sm btn-primary" @click.prevent="$emit('editar',anuncio)" disabled><svg width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg> Editar</button>
          </td>
        </tr>
        
      </tbody>
    </table>
    </div>
</template>

<script>
// import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
// import axios from 'axios';

import ListaCarga from "@/components/admin/ListaCarga.vue";

import { tableConfig } from "@/env.js";
import { capitalize } from "@/helpers.js";

export default {
  name:'ListaAnuncios',
  components: {ListaCarga},
  props:{anuncios:Object},
  data() {
    return {
      trigger:{load:true}
    }
  },
  methods: {
    capitalizeR(string){return capitalize(string)}
  },
  mounted(){
    // console.log(this.anuncios);
    setTimeout(() => {
      this.trigger.load = false
      $('#tableAnuncios').dataTable().fnDestroy();
      this.$nextTick(()=>{
        $('#tableAnuncios').dataTable(tableConfig);
      })
    }, 1000);
  },
};
</script>

<style>
.rounded-circle {
    width: 20px;
    height: 20px;
    object-fit: cover;
}
</style>