admin/<template>
    <lista-carga v-if="trigger.load" :card="false"></lista-carga>
    <div v-else>
      <!-- <h4 class="table-title">Listado de platos</h4> -->
      <table class="table" id="tablePlatos">
      <thead>
        <tr>
          <th></th>
          <th class="text-center">Código</th>
          <th>Titulo</th>
          <th>Tipo</th>
          <th class="text-center">Kcal</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(plato,i) in platos" :key="i">
          <td><img :src="(plato.imagen!=undefined)?plato.imagen:'/assets/images/healthy-preview.png'" class="rounded-circle"></td>
          <td class="text-center"><div class="badge badge-light">{{(!plato.codigo) ? '-' : plato.codigo}}</div></td>
          <td>{{capitalizeR(plato.titulo)}}</td>
          <td><div class="badge badge-success" v-for="entrada in plato.entrada" :key="entrada">{{capitalizeR((this.entradas[entrada]) ? this.entradas[entrada].nombre : entrada)}}</div></td>
          <td class="text-center"><div class="badge badge-warning">{{(plato.kcal!=undefined)?plato.kcal:'-'}}</div></td>
          <td>
            <!-- <button class="btn btn-primary mr-2" @click.prevent="$emit('mostrar',plato)">Vista previa</button> -->
            <button class="btn btn-sm btn-primary" @click.prevent="$emit('editar',plato)"><svg width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg> Editar</button>
          </td>
        </tr>
        
      </tbody>
    </table>
    </div>
</template>

<script>
// import 'jquery/dist/jquery.min.js';
//Datatable Modules
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import $ from 'jquery'; 
// import axios from 'axios';

import ListaCarga from "@/components/admin/ListaCarga.vue";

import { tableConfig } from "@/env.js";
import { capitalize } from "@/helpers.js";

export default {
  name:'ListarPosts',
  components: {ListaCarga},
  props:{platos:Object,entradas:Object},
  data() {
    return {
      trigger:{load:true}
    }
  },
  methods: {
    capitalizeR(string){return capitalize(string)}
  },
  mounted(){
    // console.log(this.platos);
    setTimeout(() => {
      this.trigger.load = false
      $('#tablePlatos').dataTable().fnDestroy();
      this.$nextTick(()=>{
        $('#tablePlatos').dataTable(tableConfig);
      })
    }, 1000);
  },
};
</script>

<style>
.rounded-circle {
    width: 20px;
    height: 20px;
    object-fit: cover;
}
</style>