import { createWebHistory, createRouter } from "vue-router";

import Dashboard from "@/views/Dashboard.vue";
import Blog from "@/views/Blog.vue";
import Platos from "@/views/Platos.vue";
import Anuncios from "@/views/Anuncios.vue";
import Trivias from "@/views/Trivias.vue";
import OperationsData from "@/views/OperationsData.vue";
import Login from "@/views/Login.vue";

// import axios from "axios";

/**
 * Status levels
 * 0 -> Administrador
 * 1 -> Usuario registrado (Entro por QR y se registró)
 * 2 -> Comensal (Entra por QR o invitación)
 * 3 -> Externo (Todavía no se logueo o entró desde afuera)
 *
 */

const routes = [
  // Admin routes
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/admin/blog",
    name: "Blog",
    component: Blog,
  },
  {
    path: "/admin/platos",
    name: "Platos",
    component: Platos,
  },
  {
    path: "/admin/anuncios",
    name: "Anuncios",
    component: Anuncios,
  },
  {
    path: "/admin/operaciones",
    name: "OperationsData",
    component: OperationsData,
  },
  {
    path: "/admin/trivias",
    name: "Trivias",
    component: Trivias,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    
  },
];

import {isset} from '@/helpers'
console.log('iss',isset);

function isAuthenticated(){
  let modulo = 'Healthy';
  // Bypass (TODO EMPLEADO EN NOMINA)
  if(modulo==null && isset(localStorage.getItem("userData"))) {
    console.log('autentikeli')
    return true;
  }
  // Si existe un modulo asignado en APP_DATA se comprueba
  if (isset(localStorage.getItem('userPermisos'))) {
    if (JSON.parse(localStorage.getItem('userPermisos')).find((record) => record.ModulosAccess === modulo)) {
      var status =  JSON.parse(localStorage.getItem('userPermisos')).find( record => record.ModulosAccess === modulo).Lector;
      return status;
    }else{
      return false;
    }
  }

  return false
}

const router = createRouter({
  history: createWebHistory(),
  routes,
  mode: 'hash',
  linkActiveClass: 'active'
});

router.beforeEach((to, from, next) => {  

  if ((to.name !== 'Login') && !isAuthenticated()) next({ name: 'Login' })
  else next()
})

export default router;
