<template>
    <div class="load-box"></div>
</template>

<script>
export default {
    name: "LoadRow",
}
</script>

<style scoped>
@keyframes placeHolderShimmer {
  0% {
    background-position: 0px 0;
  }
  100% {
    background-position: 100em 0;
  }
}


.load-box {
    border-radius: 6px;
    width: 100%;
    animation-duration: 4s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: fff;
    background: linear-gradient(to right, #eeeeee 8%, #e7e7e7 18%, #eeeeee 33%);
    height: 30px;
    -webkit-backface-visibility: hidden;
    left:0;
    right:0;
    top:0;
    bottom:0;
    transition: all .3s linear;
}

.col-1 .load-box {
    animation-duration: 10s;
    background: linear-gradient(to right, #eee 10%, #e7e7e7 40%, #eee 40%);
}

.w-25 .load-box {
    animation-duration: 8s;
    background: linear-gradient(to right, #eeeeee 8%, #e7e7e7 18%, #eeeeee 33%);
}
.w-50 .load-box {
    animation-duration: 6s;
    background: linear-gradient(to right, #eeeeee 8%, #e7e7e7 18%, #eeeeee 33%);
}
</style>
